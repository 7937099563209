import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseMessage } from '../shared/models/response-message.model';
import { Usuario } from '../shared/models/usuario.model';
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private currentUsuarioSubject: BehaviorSubject<Usuario>;
  public currentUsuario: Observable<Usuario>;

  constructor(private http: HttpClient) {
    const lsUsuario = localStorage.getItem('currentUsuario');
    if (lsUsuario != undefined || lsUsuario != null) {
      this.currentUsuarioSubject = new BehaviorSubject<Usuario>(
        JSON.parse(lsUsuario)
      );
    } else {
      localStorage.removeItem('currentUsuario');
      this.currentUsuarioSubject = new BehaviorSubject<Usuario>(null);
    }
    this.currentUsuario = this.currentUsuarioSubject.asObservable();
  }

  public get currentUsuarioValue(): Usuario {
    return this.currentUsuarioSubject.value;
  }

  login(nombreUsuario: string, contrasenia: string) {
    return this.http
      .post(`${environment.apiUrl}admin/login`, {
        nombreUsuario,
        contrasenia,
      })
      .pipe(
        map((result: ResponseMessage<Usuario>) => {
          localStorage.setItem('currentUsuario', JSON.stringify(result.entity));
          this.currentUsuarioSubject.next(result.entity);
          return result;
        })
      );
  }

  logout() {
    localStorage.removeItem('currentUsuario');
    this.currentUsuarioSubject.next(null);
  }
}
