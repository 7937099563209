<div class="clr-row">
  <div class="clr-col-6">
    <h2 class="title-page">
      <clr-icon shape="blocks-group" size="28"></clr-icon> Productos
    </h2>
  </div>
  <div class="clr-col-6">
    <button class="btn btn-primary button-nuevo" (click)="onOpenProductoNew()">
      <clr-icon shape="plus-circle"></clr-icon> Nuevo
    </button>
    <button class="btn btn-primary button-nuevo" (click)="onOpenProductoMasivos()">
      <clr-icon shape="paste"></clr-icon> Masivos
    </button>
  </div>
</div>
<hr />
<div class="clr-row">
  <div class="clr-col-lg-5 clr-col-12 search-col">
    <div class="search-container">
      <!-- <label for="search_input" class="label-search">
        <clr-icon shape="filter-grid" class="is-solid" size="22"></clr-icon>
        <input
          id="search_input"
          class="clr-input clr-input-75"
          type="text"
          [(ngModel)]="searchPattern"
          placeholder=""
        />
        <clr-icon
          (click)="onClearSearch()"
          shape="close"
          class="click-icon"
        ></clr-icon>
        <clr-icon
          (click)="onSearch()"
          shape="search"
          class="click-icon"
        ></clr-icon>
      </label> -->
    </div>
  </div>
  <div class="clr-col-lg-5 clr-col-12"></div>
  <div class="clr-col-lg-2 clr-col-12">
    <clr-toggle-container class="right-element">
      <clr-toggle-wrapper>
        <input
          type="checkbox"
          clrToggle
          name="options"
          required
          value="option1"
          [(ngModel)]="onlyActivos"
          (change)="onChangeActivos()"
        />
        <label>Solo activos</label>
      </clr-toggle-wrapper>
    </clr-toggle-container>
  </div>
</div>
<clr-datagrid
  (clrDgRefresh)="refresh($event)"
  [clrDgLoading]="loading"
  class="datagrid-compact"
>
  <clr-dg-column>Marca</clr-dg-column>
  <clr-dg-column>Nombre</clr-dg-column>
  <clr-dg-column>Resúmen</clr-dg-column>
  <clr-dg-column class="col-estado">Estado</clr-dg-column>
  <clr-dg-column class="col-command"></clr-dg-column>

  <clr-dg-row *ngFor="let producto of productos">
    <clr-dg-cell>{{ producto.marca }}</clr-dg-cell>
    <clr-dg-cell>{{ producto.nombre }}</clr-dg-cell>
    <clr-dg-cell>{{ producto.resumen }}</clr-dg-cell>
    <clr-dg-cell class="text-center col-estado"
      ><div *ngIf="producto.activo; else elseEstaActivo">
        <clr-icon shape="circle" size="18" class="is-solid is-green"></clr-icon>
      </div>
      <ng-template #elseEstaActivo
        ><clr-icon
          shape="circle"
          size="18"
          class="is-solid is-red"
        ></clr-icon></ng-template
    ></clr-dg-cell>
    <clr-dg-cell class="col-command">
      <clr-icon class="datagrid-edit-icon" title="Editar" shape="note" (click)="onOpenProductoEdit(producto.id)"></clr-icon>
    </clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer>{{ totalItems }} producto(s)</clr-dg-footer>
</clr-datagrid>

<app-productos-new *ngIf="inputProductoNew" (onCloseEventEmitter)="onCloseProductoNew($event)"></app-productos-new>
<app-productos-edit *ngIf="inputProductoEdit" (onCloseEventEmitter)="onCloseProductoEdit($event)" [inputProductoEdit]="inputProductoEdit"></app-productos-edit>
<app-productos-edit-masivos *ngIf="inputProductoMasivos" (onCloseEventEmitter)="onCloseProductoMasivos()"></app-productos-edit-masivos>
