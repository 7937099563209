import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DatagridContainer } from '../shared/models/datagrid-container.model';
import { Producto } from '../shared/models/producto.model';
import { ProductoGeneral } from '../shared/models/producto-general.model';
import { ProductoPresentacion } from '../shared/models/producto-presentacion.model';
import { ProductoVentaja } from '../shared/models/producto-ventaja.model';
import { ProductoConsejo } from '../shared/models/producto-consejo.model';
import { ProductoPreguntaFrecuente } from '../shared/models/producto-preguntafrecuente.model';
import { ResponseMessage } from '../shared/models/response-message.model';
import { ProductoMasivo, ProductoPresentacionMasivo } from '../shared/models/producto-masivo.model';

@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  constructor(private http: HttpClient) { }
  getDatagrid(itemFrom: number, pageSize: number, orderBy: string, orderReverse: boolean, onlyActivos: boolean) : Observable<DatagridContainer<Producto>> {
    return this.http.get<DatagridContainer<Producto>>(`${environment.apiUrl}admin/producto/datagrid?itemFrom=${itemFrom}&pageSize=${pageSize}&orderBy=${orderBy}&orderReverse=${orderReverse}&onlyActivos=${onlyActivos}`);
  }

  createProducto(marca: string, nombre: string, resumen: string) : Observable<ResponseMessage<any>> {
    return this.http.post<ResponseMessage<any>>(`${environment.apiUrl}admin/producto`,{ marca, nombre, resumen });
  }

  updateProducto(productoId: number, marca: string, nombre: string, resumen: string, descripcion: string, dosificacion: string, activo: boolean) : Observable<ResponseMessage<any>> {
    return this.http.put<ResponseMessage<any>>(`${environment.apiUrl}admin/producto/${productoId}`,{ marca, nombre, resumen, descripcion, dosificacion, activo });
  }

  getGeneral(id: number) : Observable<ProductoGeneral> {
    return this.http.get<ProductoGeneral>(`${environment.apiUrl}admin/producto/${id}/general`);
  }

  getPresentaciones(productoId: number) : Observable<ProductoPresentacion[]> {
    return this.http.get<ProductoPresentacion[]>(`${environment.apiUrl}admin/producto/${productoId}/presentacion`);
  }

  getPresentacion(productoPresentacionId: number) {
    return this.http.get<ProductoPresentacion>(`${environment.apiUrl}admin/producto/presentacion/${productoPresentacionId}`);
  }

  createPresentacion(productoId: number, codigo: string, descripcion: string, precio: number, stock: number) : Observable<ResponseMessage<any>> {
    return this.http.post<ResponseMessage<any>>(`${environment.apiUrl}admin/producto/${productoId}/presentacion`, {codigo, descripcion, precio,stock});
  }

  updatePresentacion(productoPresentacionId: number, codigo: string, descripcion: string, productoPresentacionPrecioId: number, precio: number, stock: number) {
    return this.http.put<ResponseMessage<any>>(`${environment.apiUrl}admin/producto/presentacion/${productoPresentacionId}`, {codigo, descripcion, productoPresentacionPrecioId, precio, stock});
  }

  // #region Ventaja
  getVentajas(productoId: number) : Observable<ProductoVentaja[]> {
    return this.http.get<ProductoVentaja[]>(`${environment.apiUrl}admin/producto/${productoId}/ventaja`);
  }

  getVentaja(productoVentajaId: number) : Observable<ProductoVentaja> {
    return this.http.get<ProductoVentaja>(`${environment.apiUrl}admin/producto/ventaja/${productoVentajaId}`);
  }

  createVentaja(productoId: number, ventaja: string) : Observable<ResponseMessage<any>> {
    return this.http.post<ResponseMessage<any>>(`${environment.apiUrl}admin/producto/${productoId}/ventaja`, { ventaja });
  }

  updateVentaja(productoVentajaId: number, ventaja: string) : Observable<ResponseMessage<any>> {
    return this.http.put<ResponseMessage<any>>(`${environment.apiUrl}admin/producto/ventaja/${productoVentajaId}`, { ventaja });
  }

  // #endregion Ventaja

  // #region Consejo
  getConsejos(productoId: number) : Observable<ProductoConsejo[]> {
    return this.http.get<ProductoConsejo[]>(`${environment.apiUrl}admin/producto/${productoId}/consejo`);
  }

  getConsejo(productoConsejoId: number) : Observable<ProductoConsejo> {
    return this.http.get<ProductoConsejo>(`${environment.apiUrl}admin/producto/consejo/${productoConsejoId}`);
  }

  createConsejo(productoId: number, descripcion: string) : Observable<ResponseMessage<any>> {
    return this.http.post<ResponseMessage<any>>(`${environment.apiUrl}admin/producto/${productoId}/consejo`, { descripcion });
  }

  updateConsejo(productoConsejoId: number, descripcion: string) : Observable<ResponseMessage<any>> {
    return this.http.put<ResponseMessage<any>>(`${environment.apiUrl}admin/producto/consejo/${productoConsejoId}`, { descripcion });
  }

  // #endregion Consejo

  getPreguntasFrecuentes(productoId: number) : Observable<ProductoPreguntaFrecuente[]> {
    return this.http.get<ProductoPreguntaFrecuente[]>(`${environment.apiUrl}admin/producto/${productoId}/preguntafrecuente`);
  }

  getProductoUuid(productoId: number) : Observable<ResponseMessage<string>> {
    return this.http.get<ResponseMessage<string>>(`${environment.apiUrl}admin/producto/${productoId}/uuid`);
  }

  updateImagen(archivo: string, nombreArchivo: string, uuid: string, tipoArchivo: number) : Observable<ResponseMessage<any>> {
    return this.http.put<ResponseMessage<any>>(`${environment.apiUrl}admin/producto/imagen`, { uuid, archivo, nombreArchivo, tipoArchivo });
  }

  // #region Masivos

  getMasivos() : Observable<ProductoMasivo[]> {
    return this.http.get<ProductoMasivo[]>(`${environment.apiUrl}admin/producto/masivo`);
  }

  putMasivos(productoPresentacionMasivo: { id: number, stock: number, precio: number }[]) : Observable<ResponseMessage<any>> {
    return this.http.put<ResponseMessage<any>>(`${environment.apiUrl}admin/producto/masivo`, productoPresentacionMasivo);
  }

  // #endregion Masivos
}

