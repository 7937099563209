import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfiguracionComponent } from './pages/configuracion/configuracion.component';
import { ClarityModule } from '@clr/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ConfiguracionComponent],
  imports: [CommonModule, ClarityModule, FormsModule, ReactiveFormsModule],
})
export class ConfiguracionModule {}
