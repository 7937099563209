import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Pedido } from 'src/app/shared/models/pedido.model';
import { PedidosService } from '../../pedidos.service';
import { ResponseMessage } from 'src/app/shared/models/response-message.model';

@Component({
  selector: 'app-pedidos-edit',
  templateUrl: './pedidos-edit.component.html',
  styleUrls: ['./pedidos-edit.component.css'],
})
export class PedidosEditComponent implements OnInit {
  @Input() pedidoId: number;
  @Output() onCloseEventEmmiter = new EventEmitter<boolean>();
  form: FormGroup = this.formBuilder.group({});
  pedido: Pedido;
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private pedidoService: PedidosService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      estado: ['', Validators.required],
      observaciones:['', [Validators.required]]
    });
    this.pedidoService.getPedido(this.pedidoId).subscribe((result: Pedido) => {
      this.pedido = result;
      this.form.patchValue({ estado: this.pedido.estado });
    });
  }

  onCloseEdit(refresh: boolean) {
    this.onCloseEventEmmiter.emit(false);
  }

  onSave() {
    this.pedidoService.updatePedido(this.pedido.id, Number( this.form.get('estado').value), this.form.get('observaciones').value).subscribe((result: ResponseMessage<any>) => {
      if(result.success) {
        this.toastr.success("Se actualizó el estado");
        this.onCloseEventEmmiter.emit(true);
      }
      else {
        this.toastr.error(result.message);
      }
    })
  }
}
