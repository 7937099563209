import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ClrForm, ClrLoadingState } from '@clr/angular';
import { ProductosService } from '../../productos.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductoVentaja } from 'src/app/shared/models/producto-ventaja.model';
import { ResponseMessage } from 'src/app/shared/models/response-message.model';

@Component({
  selector: 'app-productos-edit-ventajas-edit',
  templateUrl: './productos-edit-ventajas-edit.component.html',
  styleUrls: ['./productos-edit-ventajas-edit.component.css']
})
export class ProductosEditVentajasEditComponent implements OnInit {
  @Input() inputVentajaEdit: InputVentajaEdit;
  @Output() onCloseEventEmitter = new EventEmitter<boolean>();
  @ViewChild(ClrForm) clrForm;
  saveBtn: ClrLoadingState = ClrLoadingState.DEFAULT;
  loadingWindow = true;
  formGroup;
  constructor(private productoService: ProductosService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      id: new FormControl(),
      ventaja: new FormControl('', Validators.required),
    });
    this.productoService
    .getVentaja(this.inputVentajaEdit.productoVentajaId)
    .subscribe((result: ProductoVentaja) => {
      this.formGroup.patchValue({
        id: result.id,
        ventaja: result.descripcion
      });
      this.loadingWindow = false;
    });
  }

  onClose() {
    this.onCloseEventEmitter.emit(false);
  }

  onSave() {
    if (this.formGroup.invalid) {
       this.clrForm.markAsTouched();
    } else {
       this.saveBtn = ClrLoadingState.LOADING;
       this.productoService
         .updateVentaja(
           this.inputVentajaEdit.productoVentajaId,
           this.formGroup.get('ventaja').value,
         )
         .subscribe(
           (result: ResponseMessage<any>) => {
             if (result.success) {
               this.toastr.success("Se actualizó la ventaja.");
               this.onCloseEventEmitter.emit(true);
             } else {
               this.toastr.error(result.message);
             }
           },
           () => {
             this.saveBtn = ClrLoadingState.DEFAULT;
           }
         );
    }
  }
}



export class InputVentajaEdit {
  productoVentajaId: number;
}
