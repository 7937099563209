import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppLayoutComponent } from './pages/app-layout/app-layout.component';
import { ClarityModule } from '@clr/angular';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [AppLayoutComponent],
  imports: [CommonModule, ClarityModule, RouterModule],
})
export class LayoutModule {}
