<div class="clr-row">
  <div class="clr-col-6">
    <h2 class="title-page">
      <clr-icon shape="shopping-cart" size="28"></clr-icon> Pedidos
    </h2>
  </div>
  <div class="clr-col-6">
  </div>
</div>
<hr />
<div class="clr-row">
  <div class="clr-col-lg-5 clr-col-12 search-col">
    <div class="search-container">
      <!-- <label for="search_input" class="label-search">
        <clr-icon shape="filter-grid" class="is-solid" size="22"></clr-icon>
        <input
          id="search_input"
          class="clr-input clr-input-75"
          type="text"
          [(ngModel)]="searchPattern"
          placeholder=""
        />
        <clr-icon
          (click)="onClearSearch()"
          shape="close"
          class="click-icon"
        ></clr-icon>
        <clr-icon
          (click)="onSearch()"
          shape="search"
          class="click-icon"
        ></clr-icon>
      </label> -->
    </div>
  </div>
  <div class="clr-col-lg-5 clr-col-12">
    <!-- <clr-select-container>
      <label>Estado</label>
      <select
        clrSelect
        name="empresasOptions"
      >
        <option value="0">Todos</option>
        <option value="1">Pendiente de cobro</option>
        <option value="2">En proceso</option>
        <option value="3">En camino</option>
        <option value="4">Entregado</option>
        <option value="5">Cancelado</option>
      </select>
    </clr-select-container> -->
  </div>
  <div class="clr-col-lg-2 clr-col-12">
    <clr-toggle-container class="right-element" >
      <clr-toggle-wrapper>
        <input type="checkbox" clrToggle name="options" required value="option1" [(ngModel)]="soloAbiertos" />
        <label>Solo abiertos</label>
      </clr-toggle-wrapper>
    </clr-toggle-container>
  </div>
</div>
<clr-datagrid
(clrDgRefresh)="refresh($event)"
[clrDgLoading]="loading"
class="datagrid-compact">
  <clr-dg-column>#</clr-dg-column>
  <clr-dg-column>Cliente</clr-dg-column>
  <clr-dg-column>Medio de pago</clr-dg-column>
  <clr-dg-column>Estado</clr-dg-column>
  <clr-dg-column>Fecha</clr-dg-column>
  <clr-dg-column>Total</clr-dg-column>
  <clr-dg-column class="col-actions"></clr-dg-column>

  <clr-dg-row *ngFor="let pedido of pedidos" [clrDgItem]="pedido">
    <clr-dg-cell>{{pedido.numero}}</clr-dg-cell>
    <clr-dg-cell>{{pedido.nombreCliente}}</clr-dg-cell>
    <clr-dg-cell>{{pedido.medioPagoDescripcion}}</clr-dg-cell>
    <clr-dg-cell>{{pedido.estadoDescripcion}}</clr-dg-cell>
    <clr-dg-cell>{{pedido.fechaCreacion | date: 'dd/MM/yyyy HH:mm'}}</clr-dg-cell>
    <clr-dg-cell class="center">{{pedido.total | currency }}</clr-dg-cell>
    <clr-dg-cell class="col-actions center"><span *ngIf="pedido.estado != 99"><clr-icon class="datagrid-edit-icon" title="Editar" shape="note" (click)="onEditPedido(pedido)"></clr-icon></span></clr-dg-cell>
  </clr-dg-row>
  <clr-dg-detail *clrIfDetail="let pedido">
    <app-pedidos-detalle [pedidoId]="pedido.id" ngProjectAs="clr-dg-row-detail"></app-pedidos-detalle>
  </clr-dg-detail>
  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="15"
    [clrDgTotalItems]="totalItems">
        <clr-dg-page-size [clrPageSizeOptions]="[10,15, 20,50]">Pedidos por página</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
        of {{totalItems}} pedidos
    </clr-dg-pagination>
</clr-dg-footer>
</clr-datagrid>

<app-pedidos-edit *ngIf="pedidoIdSelected" (onCloseEventEmmiter)="onEditClose($event)" [pedidoId]="pedidoIdSelected"></app-pedidos-edit>
