<clr-modal [clrModalOpen]="true" [clrModalClosable]="false">
  <h3 class="modal-title">Editar cliente</h3>
  <div class="modal-body">
    <form clrForm clrLayout="Compact" clrLabelSize="3" [formGroup]="form">
      <clr-tabs>
        <!-- #region General  -->
        <clr-tab>
          <button clrTabLink id="link1">General</button>
          <clr-tab-content id="content1" *clrIfActive>
            <clr-select-container>
              <label>Tipo cliente</label>
              <select clrSelect name="options" formControlName="tipoCliente">
                <option value="1">Cliente final</option>
                <option value="2">Growshop</option>
                <option value="3">Distribuidor</option>
              </select>
            </clr-select-container>
            <clr-input-container>
              <label>Nombre cliente</label>
              <input clrInput type="text" name="nombreCliente" maxlength="128" formControlName="nombreCliente"
                class="input-90" />
              <clr-control-error>Debe ingresar un nombre de cliente.</clr-control-error>
            </clr-input-container>
            <clr-select-container>
              <label>Tipo doc. fiscal</label>
              <select clrSelect name="options" formControlName="tipoDocumentoFiscal">
                <option value="1">IVA Responsable Inscripto</option>
                <option value="4">IVA Sujeto Exento</option>
                <option value="5">Consumidor Final</option>
                <option value="6">Responsable Monotributo</option>
                <option value="7">Sujeto No Categorizado</option>
                <option value="8">Proveedor del Exterior</option>
                <option value="9">Cliente del Exterior</option>
                <option value="10">IVA Liberado - Ley Nº 19.640</option>
                <option value="13">Monotributista Social</option>
                <option value="15">IVA No Alcanzado</option>
                <option value="16">Monotrib. Trabajador Indep. Promovido</option>
              </select>
            </clr-select-container>
            <clr-input-container>
              <label>Doc. fiscal</label>
              <input clrInput type="text" name="documentoFiscal" maxlength="128" formControlName="documentoFiscal"
                class="input-90" />
              <clr-control-error>Debe ingresar un documento fiscal.</clr-control-error>
            </clr-input-container>
            <clr-input-container>
              <label>Nro. Whatsapp</label>
              <input clrInput type="text" name="whatsapp" maxlength="128" formControlName="whatsapp"
                class="input-90" />
              <clr-control-error>Debe ingresar un número de Whatsapp.</clr-control-error>
            </clr-input-container>

            <clr-input-container>
              <label>Instagram</label>
              <input clrInput type="text" name="instagram" maxlength="128" formControlName="instagram"
                class="input-90" />
              <clr-control-error>Debe ingresar un instagram.</clr-control-error>
            </clr-input-container>

            <clr-input-container>
              <label>Web</label>
              <input clrInput type="text" name="web" maxlength="128" formControlName="web"
                class="input-90" />
              <clr-control-error>Debe ingresar una Web.</clr-control-error>
            </clr-input-container>

          </clr-tab-content>
        </clr-tab>
        <!-- #endregion General  -->
        <!-- #region Usuario  -->
        <clr-tab>
          <button clrTabLink>Usuario</button>
          <clr-tab-content *clrIfActive>
            <clr-input-container>
              <label>Nombre</label>
              <input clrInput type="text" name="nombre" maxlength="128" formControlName="nombre" class="input-90" />
              <clr-control-error>Debe ingresar un nombre.</clr-control-error>
            </clr-input-container>
            <clr-input-container>
              <label>Apellido</label>
              <input clrInput type="text" name="apellido" maxlength="128" formControlName="apellido" class="input-90" />
              <clr-control-error>Debe ingresar un apellido.</clr-control-error>
            </clr-input-container>
            <clr-input-container>
              <label>Correo eléctronico</label>
              <input
                clrInput
                type="text"
                name="correoElectronico"
                maxlength="128"
                formControlName="correoElectronico"
                class="input-90"
              />
              <clr-control-error
                >Debe ingresar un correo eléctronico.</clr-control-error
              >
            </clr-input-container>
            <div class="clr-col-3 clr-col-sm-6">
              <div class="formulario-combos">
                <div *ngIf="cliente?.verificado; else elseEstaVerificado"><clr-icon shape="check" size="18" class="is-solid is-green"></clr-icon>  Verificado</div>
                <ng-template #elseEstaVerificado>
                  <clr-icon shape="close" size="18" class="is-solid is-red"></clr-icon>  Sin verificar
                  <!-- <clr-icon shape="refresh" size="18" class="is-solid is-green" *ngIf="!loadingReenvioVerificacion" title="Reenviar verificación" (click)="onResendVerification()"></clr-icon> -->
                  <!-- <span class="spinner spinner-inline" *ngIf="loadingReenvioVerificacion">
                    Loading...
                </span> -->
                </ng-template>
              </div>
            </div>
            <div class="clr-col-3 clr-col-sm-6">
              <div class="formulario-combos">
                <clr-toggle-wrapper>
                  <input
                    type="checkbox"
                    clrToggle
                    value="option2"
                    name="options"
                    formControlName="activo"
                  />
                  <label>Activo</label>
                </clr-toggle-wrapper>
              </div>
            </div>
            <!-- <clr-checkbox-container>
              <clr-checkbox-wrapper>
                <label>Activo</label>
                <input type="checkbox" clrCheckbox value="option2" formControlName="activo"/>
              </clr-checkbox-wrapper>
            </clr-checkbox-container> -->
          </clr-tab-content>
        </clr-tab>
        <!-- #endregion Usuario  -->
        <!-- #region Domicilio  -->
        <clr-tab>
          <button clrTabLink>Domicilio</button>
          <clr-tab-content *clrIfActive>
            <clr-input-container>
              <label>Domicilio</label>
              <input
                clrInput
                type="text"
                name="domicilio"
                maxlength="1024"
                formControlName="domicilio"
                class="input-90"
              />
              <clr-control-error>Debe ingresar un domicilio.</clr-control-error>
            </clr-input-container>
          <clr-input-container>
            <label>Ciudad/Localidad</label>
            <input
              clrInput
              type="text"
              name="ciudad"
              maxlength="128"
              formControlName="ciudad"
              class="input-90"
            />
            <clr-control-error>Debe ingresar un ciudad/localidad.</clr-control-error>
          </clr-input-container>
          <clr-input-container>
            <label>Cóidgo Postal</label>
            <input
              clrInput
              type="text"
              name="codigoPostal"
              maxlength="128"
              formControlName="codigoPostal"
              class="input-90"
            />
            <clr-control-error>Debe ingresar un código postal.</clr-control-error>
          </clr-input-container>
          <clr-select-container>
            <label>Provincia</label>
            <select clrSelect name="options" formControlName="provincia">
              <option value="1">Buenos Aires</option>
              <option value="2">Catamarca</option>
              <option value="3">Chaco</option>
              <option value="4">Chubut</option>
              <option value="5">Ciudad Autónoma de Buenos Aires</option>
              <option value="6">Córdoba</option>
              <option value="7">Corrientes</option>
              <option value="8">Entre Ríos</option>
              <option value="9">Formosa</option>
              <option value="10">La Pampa</option>
              <option value="11">La Rioja</option>
              <option value="12">Mendoza</option>
              <option value="13">Misiones</option>
              <option value="14">Neuquén</option>
              <option value="15">Río Negro</option>
              <option value="16">Salta</option>
              <option value="17">San Juan</option>
              <option value="18">San Luis</option>
              <option value="19">Santa Cruz</option>
              <option value="20">Santiago del Estero</option>
              <option value="21">Santa Fe</option>
              <option value="22">Tierra del Fuego, Antártida e Islas del Atlántico Sur</option>
              <option value="23">Tucumán</option><option   value="24">Jujuy</option>
            </select>
          </clr-select-container>
          <clr-select-container>
            <label>País</label>
            <select clrSelect name="options" formControlName="pais">
              <option value="1">Argentina</option>
            </select>
          </clr-select-container>
        </clr-tab-content>
        </clr-tab>
        <!-- #endregion Domicilio  -->
      </clr-tabs>
        </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="onClose(false)">Cerrar</button>
    <button type="button" class="btn btn-primary" (click)="onSave()">Grabar</button>
  </div>
</clr-modal>
