<div class="modal-container">
  <div class="clr-row">
    <div class="clr-col-12">
      <button class="btn btn-sm btn-primary" style="float: right;" (click)="onShowConsejoAdd()">
        <clr-icon shape="plus-circle"></clr-icon> Nuevo
      </button>
    </div>
  </div>
  <table class="table table-compact">
    <thead>
        <tr>
            <th class="left">Descripción</th>
            <th class="left"></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let productoConsejo of productoConsejos">
            <td class="left">{{productoConsejo.descripcion}}</td>
            <td class="left col-command">
              <clr-icon class="datagrid-edit-icon" title="Editar" shape="note" (click)="onShowConsejoEdit(productoConsejo.id)"></clr-icon>
            </td>
        </tr>
    </tbody>
  </table>
</div>
<div class="clr-row">
  <div class="clr-col-12">
    <button type="button" class="btn" (click)="onClose()" [disabled]="saveBtn == 1" style="float: right;">Cerrar</button>
  </div>
</div>

<app-productos-edit-consejos-add *ngIf="inputConsejoAdd" [inputProductoEditId]="productoId" (onCloseEventEmitter)="onCloseConsejoAdd($event)"></app-productos-edit-consejos-add>
<app-productos-edit-consejos-edit *ngIf="inputConsejoEdit" [inputConsejoEdit]="inputConsejoEdit" (onCloseEventEmitter)="onCloseVentajaEdit($event)"></app-productos-edit-consejos-edit>
