import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrForm, ClrLoadingState } from '@clr/angular';
import { ResponseMessage } from 'src/app/shared/models/response-message.model';
import { Usuario } from 'src/app/shared/models/usuario.model';
import { LoginService } from '../login.service';
import { first } from 'rxjs/operators'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild(ClrForm) clrForm;
  loginBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;
  loginForm: FormGroup;
  submitted = false;
  error: boolean = false;
  errorMessage: string = '';

  constructor(private router: Router, private formBuilder: FormBuilder, private route: ActivatedRoute, private loginService: LoginService) { }

  ngOnInit(): void {
    this.loginService.logout();
    this.loginForm = this.formBuilder.group({
      usuario: ['', Validators.required],
      contrasenia: ['', Validators.required],
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  onLogin() {
    this.errorMessage = '';
    this.error = false;
    this.submitted = true;
    if (this.loginForm.invalid) {
      return this.loginForm.markAsDirty();
    }
    this.loginBtnState = ClrLoadingState.LOADING;
    this.loginService
      .login(this.f['usuario'].value, this.f['contrasenia'].value)
      .pipe(first())
      .subscribe({
        next: (result: ResponseMessage<Usuario>) => {
          if (this.loginService.currentUsuarioValue != null) {
            const returnUrl =
              this.route.snapshot.queryParams['returnUrl'] || '/pedidos';
            this.router.navigate([returnUrl]);
            this.loginBtnState = ClrLoadingState.SUCCESS;
          } else {
            this.errorMessage = result.message;
            this.error = true;
            this.loginBtnState = ClrLoadingState.ERROR;
          }
        },
        error: (error) => {
          this.error = error;
          this.errorMessage = "Se ha producido un error interno.";
          this.loginBtnState = ClrLoadingState.ERROR;
        },
      });
  }
}
