<div class="modal-container">
  <div class="clr-row">
  <div class="clr-col-12">
    <button class="btn btn-sm btn-primary" style="float: right;" (click)="onShowVentajaAdd()">
      <clr-icon shape="plus-circle"></clr-icon> Nuevo
    </button>
  </div>
</div>
  <table class="table table-compact">
    <thead>
        <tr>
            <th class="left">Descripción</th>
            <th class="left"></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let productoVentaja of productoVentajas">
            <td class="left">{{productoVentaja.descripcion}}</td>
            <td class="left col-command">
              <clr-icon class="datagrid-edit-icon" title="Editar" shape="note" (click)="onShowVentajaEdit(productoVentaja.id)"></clr-icon>
            </td>

        </tr>
    </tbody>
  </table>
</div>
<div class="clr-row">
  <div class="clr-col-12">
    <button type="button" class="btn" (click)="onClose()" [disabled]="saveBtn == 1" style="float: right;">Cerrar</button>
  </div>
</div>

<app-productos-edit-ventajas-add *ngIf="inputVentajaAdd" [inputProductoEditId]="productoId" (onCloseEventEmitter)="onCloseVentajaAdd($event)"></app-productos-edit-ventajas-add>
<app-productos-edit-ventajas-edit *ngIf="inputVentajaEdit" [inputVentajaEdit]="inputVentajaEdit" (onCloseEventEmitter)="onCloseVentajaEdit($event)"></app-productos-edit-ventajas-edit>

