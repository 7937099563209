import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClrForm, ClrLoadingState } from '@clr/angular';
import { ToastrService } from 'ngx-toastr';
import { ResponseMessage } from 'src/app/shared/models/response-message.model';
import { ProductosService } from '../../productos.service';

@Component({
  selector: 'app-productos-edit-ventajas-add',
  templateUrl: './productos-edit-ventajas-add.component.html',
  styleUrls: ['./productos-edit-ventajas-add.component.css']
})
export class ProductosEditVentajasAddComponent implements OnInit {
  @Input() inputProductoEditId: number;
  @Output() onCloseEventEmitter = new EventEmitter<boolean>();
  @ViewChild(ClrForm) clrForm;
  saveBtn: ClrLoadingState = ClrLoadingState.DEFAULT;
  formGroup;
  constructor(private productoService: ProductosService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      ventaja: new FormControl('', Validators.required)
    });
  }

  onClose() {
    this.onCloseEventEmitter.emit(true);
  }

  onSave() {
    if (this.formGroup.invalid) {
      this.clrForm.markAsTouched();
    } else {
      this.saveBtn = ClrLoadingState.LOADING;
      this.productoService
        .createVentaja(
          this.inputProductoEditId,
          this.formGroup.get('ventaja').value)
        .subscribe(
          (result: ResponseMessage<any>) => {
            if (result.success) {
              this.toastr.success("Se agregó la ventaja.");
              this.onCloseEventEmitter.emit(true);
            } else {
              this.toastr.error(result.message);
            }
          },
          () => {
            this.saveBtn = ClrLoadingState.DEFAULT;
          }
        );
    }
  }

}
