import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DatagridContainer } from '../shared/models/datagrid-container.model';
import { PedidoItem } from '../shared/models/pedido-item.model';
import { Pedido } from '../shared/models/pedido.model';

@Injectable({
  providedIn: 'root'
})
export class PedidosService {

  constructor(private http: HttpClient) { }

  getDatagrid(itemFrom: number, pageSize: number, orderBy: string, orderReverse: boolean) : Observable<DatagridContainer<Pedido>> {
    return this.http.get<DatagridContainer<Pedido>>(`${environment.apiUrl}admin/pedido/datagrid?itemFrom=${itemFrom}&pageSize=${pageSize}&orderBy=${orderBy}&orderReverse=${orderReverse}`);
  }

  getDatagridDetalle(pedidoId: number) : Observable<PedidoItem[]> {
    return this.http.get<PedidoItem[]>(`${environment.apiUrl}admin/pedido/datagrid/items?id=${pedidoId}`);
  }

  getPedido(id: number) {
    return this.http.get<Pedido>(`${environment.apiUrl}admin/pedido/${id}`);
  }

  updatePedido(id: number, estadoId: number, observaciones: string) {
    return this.http.put(`${environment.apiUrl}admin/pedido/${id}/estado`, {estadoId, observaciones});
  }

}
