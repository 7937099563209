import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DatagridContainer } from '../shared/models/datagrid-container.model';
import { Cliente } from '../shared/models/cliente.model';
import { environment } from 'src/environments/environment';
import { ResponseMessage } from '../shared/models/response-message.model';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  constructor(private http: HttpClient) { }

  getDatagrid(itemFrom: number, pageSize: number, orderBy: string, orderReverse: boolean) : Observable<DatagridContainer<Cliente>> {
    return this.http.get<DatagridContainer<Cliente>>(`${environment.apiUrl}cliente/datagrid?itemFrom=${itemFrom}&pageSize=${pageSize}&orderBy=${orderBy}&orderReverse=${orderReverse}`);
  }

  getById(id: number) {
    return this.http.get<Cliente>(`${environment.apiUrl}admin/cliente/${id}`);
  }

  put(id: number, usuarioId: number, tipoCliente: number, nombreCliente: string, tipoDocumentoFiscal: number, documentoFiscal: string, whatsapp: string, web: string, instagram: string, nombre: string, apellido: string,  activo: boolean, domicilio: string, ciudad: string, codigoPostal: string, provinciaId: number, paisId: number, tipoUsuario: number) {
    return this.http.put<ResponseMessage<any>>(`${environment.apiUrl}admin/cliente/${id}`, { usuarioId, tipoCliente, nombreCliente, tipoDocumentoFiscal, documentoFiscal, whatsapp, web, instagram, nombre, apellido, activo, domicilio, ciudad, codigoPostal, provinciaId, paisId, tipoUsuario });
  }
}
