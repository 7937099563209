<clr-modal
  [clrModalOpen]="true"
  [clrModalClosable]="false"
  class="modal-line-height"
  [clrModalSize]="'lg'"
>
  <h3 class="modal-title">Editar producto</h3>
  <div class="modal-body">
    <clr-tabs>
      <clr-tab>
        <button clrTabLink id="link1">General</button>
        <clr-tab-content id="content1" *clrIfActive="true">
          <app-productos-edit-general
            [productoId]="inputProductoEdit.productoId"
            (onCloseEventEmitter)="onClose()"
          ></app-productos-edit-general>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink id="link1">Presentaciones</button>
        <clr-tab-content>
          <app-productos-edit-presentaciones
            [productoId]="inputProductoEdit.productoId"
            (onCloseEventEmitter)="onClose()"
          ></app-productos-edit-presentaciones>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink id="link1">Ventajas</button>
        <clr-tab-content>
          <app-productos-edit-ventajas
            [productoId]="inputProductoEdit.productoId" (onCloseEventEmitter)="onClose()"
          ></app-productos-edit-ventajas>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink id="link1">Consejos</button>
        <clr-tab-content>
          <app-productos-edit-consejos
            [productoId]="inputProductoEdit.productoId"
            (onCloseEventEmitter)="onClose()"
          ></app-productos-edit-consejos>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink id="link1">Preguntas Frecuentes</button>
        <clr-tab-content>
          <app-productos-edit-preguntasfrecuentes
            [productoId]="inputProductoEdit.productoId"
            (onCloseEventEmitter)="onClose()"
          ></app-productos-edit-preguntasfrecuentes>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink id="link1">Imagenes</button>
        <clr-tab-content>
          <app-productos-edit-fotos [productoId]="inputProductoEdit.productoId" (onCloseEventEmitter)="onClose()"></app-productos-edit-fotos>
        </clr-tab-content>
      </clr-tab>
    </clr-tabs>
  </div>
</clr-modal>
