import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClienteComponent } from './clientes/pages/cliente/cliente.component';
import { ConfiguracionComponent } from './configuracion/pages/configuracion/configuracion.component';
import { AppLayoutComponent } from './layout/pages/app-layout/app-layout.component';
import { PedidosComponent } from './pedidos/pages/pedidos/pedidos.component';
import { ProductosComponent } from './productos/pages/productos/productos.component';
import { UsuariosComponent } from './usuarios/pages/usuarios/usuarios.component';
import { ContactosComponent } from './contactos/pages/contactos/contactos.component';
import { LoginComponent } from './login/pages/login.component';
import { AuthGuard } from './shared/helpers/auth.guard';

const routes: Routes = [
  {
     path: '',
     component: LoginComponent,
     pathMatch: 'full'
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
     children: [
       { path: 'pedidos', component: PedidosComponent },
       { path: 'productos', component: ProductosComponent },
       { path: 'clientes', component: ClienteComponent },
       { path: 'usuarios', component: UsuariosComponent },
       { path: 'contactos', component: ContactosComponent },
       { path: 'configuracion', component: ConfiguracionComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
