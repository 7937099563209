<clr-modal [clrModalOpen]="true" [clrModalClosable]="false" class="modal-line-height">
  <h3 class="modal-title">Nuevo producto</h3>
  <div class="modal-body">
    <form clrForm clrLayout="horizontal" [formGroup]="formGroup">
      <clr-input-container>
        <label>Marca</label>
        <input clrInput type="text" name="marca" formControlName="marca" />
        <clr-control-error>El campo marca es requerido.</clr-control-error>
      </clr-input-container>
      <clr-input-container>
        <label>Nombre</label>
        <input clrInput type="text" name="nombre" formControlName="nombre" required />
        <clr-control-error>El campo nombre es requerido.</clr-control-error>
      </clr-input-container>
      <clr-textarea-container>
        <label>Resúmen</label>
        <textarea clrTextarea name="resumen" style="width: 100%;" formControlName="resumen" required></textarea>
        <clr-control-error>El campo resúmen es requerido.</clr-control-error>
      </clr-textarea-container>
    </form>
    <div class="modal-footer">
      <button type="button" class="btn" (click)="onClose()" [disabled]="saveBtn == 1 || saveEditBtn == 1">Cerrar</button>
      <button type="button" class="btn" [clrLoading]="saveBtn" (click)="onSave()" [disabled]="saveBtn == 1 || saveEditBtn == 1">Agregar</button>
      <button type="button" class="btn btn-primary" [clrLoading]="saveEditBtn" (click)="onSaveEdit()" [disabled]="saveBtn == 1 || saveEditBtn == 1">Agregar y Editar</button>
    </div>
  </div>
</clr-modal>
