import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClrLoadingState } from '@clr/angular';
import { ProductoConsejo } from 'src/app/shared/models/producto-consejo.model';
import { ProductosService } from '../../productos.service';
import { InputConsejoEdit } from '../productos-edit-consejos-edit/productos-edit-consejos-edit.component';

@Component({
  selector: 'app-productos-edit-consejos',
  templateUrl: './productos-edit-consejos.component.html',
  styleUrls: ['./productos-edit-consejos.component.css'],
})
export class ProductosEditConsejosComponent implements OnInit {
  @Input() productoId: number;
  @Output() onCloseEventEmitter = new EventEmitter<boolean>();
  saveBtn: ClrLoadingState = ClrLoadingState.DEFAULT;
  productoConsejos: ProductoConsejo[] = [];
  loadingWindow = true;

  constructor(private productoService: ProductosService) {}

  ngOnInit(): void {
    this.getConsejos();
  }

  getConsejos() {
    this.productoService
      .getConsejos(this.productoId)
      .subscribe((result: ProductoConsejo[]) => {
        this.productoConsejos = result;
      });
  }

  onClose() {
    this.onCloseEventEmitter.emit(false);
  }

  /* #region Ventaja New */
  inputConsejoAdd: boolean | undefined = undefined;

  onShowConsejoAdd() {
    this.inputConsejoAdd = true;
  }

  onCloseConsejoAdd(refresh) {
    if (refresh) this.getConsejos();
    this.inputConsejoAdd = undefined;
  }

  /* #endregion Ventaja New */

  /* #region Ventaja Edit */
  inputConsejoEdit: InputConsejoEdit = undefined;

   onShowConsejoEdit(productoConsejoId: number) {
      this.inputConsejoEdit = {
       productoConsejoId: productoConsejoId,
      };
   }

   onCloseVentajaEdit(refresh) {
      if(refresh)
        this.getConsejos();
      this.inputConsejoEdit = undefined;
   }
  /* #endregion Ventaja Edit */
}
