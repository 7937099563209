<div [clrLoading]="loading">
<div class="detail-container">
  <div class="clr-row">
    <div class="clr-col-6">
      <h4>Pedido número: {{ pedido?.numero }}</h4>
    </div>
  </div>
  <div class="clr-row">
    <div class="clr-col-6">
      <span class="p4">Nombre cliente:</span
      ><span class="p3"> {{ pedido?.nombreCliente }}</span>
    </div>
    <div class="clr-col-6">
      <span class="p4">Medio de pago:</span
      ><span class="p3"> {{ pedido?.medioPagoDescripcion }}</span>
    </div>
  </div>
  <div class="clr-row">
    <div class="clr-col-6">
      <span class="p4">Fecha de pedido:</span
      ><span class="p3">
        {{ pedido?.fechaCreacion | date: "dd/MM/yyyy HH:mm" }}</span
      >
    </div>
    <div class="clr-col-6">
      <span class="p4">Estado:</span
      ><span class="p3">
        {{ pedido?.estadoDescripcion }}</span
      >
    </div>
  </div>
  <table class="table table-compact">
    <thead>
      <tr>
        <th>Nombre de producto</th>
        <th>Presentación de producto</th>
        <th>Cantidad</th>
        <th>Precio Lista</th>
        <th>Descuento</th>
        <th>Total $</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let pedidoItem of pedido.items">
        <td class="left">{{ pedidoItem.productoNombre }}</td>
        <td class="left">{{ pedidoItem.productoPresentacionDescripcion }}</td>
        <td class="right">{{ pedidoItem.cantidad }}</td>
        <td class="right">{{ pedidoItem.precio | currency }}</td>
        <td class="right">{{ pedido.descuento }}%</td>
        <td class="right">{{ pedidoItem.total | currency }}</td>
      </tr>
    </tbody>
  </table>
  <br>
  <div class="clr-row">
    <div class="clr-col-12">
      <span class="p4">Subtotal:</span><span class="p3"> {{ pedido?.subTotal | currency }}</span>
    </div>
    <div class="clr-col-12">
      <span class="p4">Recargo:</span><span class="p3"> {{ pedido?.recargo | currency }}</span>
    </div>
    <div class="clr-col-12">
      <span class="p4">IVA {{pedido.iva}}%:</span><span class="p3"> {{ pedido?.ivaImporte | currency }}</span>
    </div>
    <div class="clr-col-12">
      <span class="p4">Envio:</span><span class="p3"> {{ pedido?.envio | currency }}</span>
    </div>
    <div class="clr-col-12">
      <span class="p4">Total:</span><span class="p3"><strong> {{ pedido?.total | currency }}</strong></span>
    </div>
  </div>
</div>
</div>
