<div class="clr-row" *ngIf="!loading">
  <div class="clr-col-6">
    <div style="border: 1px solid lightgrey; border-radius: 5px; margin: 4px">
      <img [src]="getUrlImagen(1)" alt="Imagen producto" />
      <!-- <clr-icon class="folder-icon click-icon" size="28" shape="folder"></clr-icon> -->
      <button
        style="
          padding: 0px !important;
          margin-left: 10px;
          width: 200px;
          padding: 0px;
          width: 140px;
        "
        class="btn btn-sm btn-link"
        title="Adjuntar archivo"
        (click)="onAttachFile1()"
      >
        <clr-icon shape="folder"></clr-icon> Subir imagen.
      </button>
    </div>
  </div>
  <div class="clr-col-6">
    <div style="border: 1px solid lightgrey; border-radius: 5px; margin: 4px">
      <img [src]="getUrlImagen(2)" alt="Imagen producto" />
      <button
        style="
          padding: 0px !important;
          margin-left: 10px;
          width: 200px;
          padding: 0px;
          width: 140px;
        "
        class="btn btn-sm btn-link"
        title="Adjuntar archivo"
        (click)="onAttachFile2()"
      >
        <clr-icon shape="folder"></clr-icon> Subir imagen.
      </button>
    </div>
  </div>
</div>
<div class="clr-row">
  <div class="clr-col-12">
    <button type="button" class="btn" (click)="onClose()" style="float: right">
      Cerrar
    </button>
  </div>
</div>
<input type="file" #attach class="file-upload" style="display: none" accept="image/png" />
