import { Component, OnInit } from '@angular/core';
import { ClrDatagridStateInterface } from '@clr/angular';
import { DatagridContainer } from 'src/app/shared/models/datagrid-container.model';
import { Producto } from 'src/app/shared/models/producto.model';
import { ProductosService } from '../../productos.service';
import { InputProductoEdit } from '../../components/productos-edit/productos-edit.component';
import { OutputProductoNew } from '../../components/productos-new/productos-new.component';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css'],
})
export class ProductosComponent implements OnInit {
  searchPattern: string = '';
  onlyActivos: boolean = true;
  openModalNewProducto: boolean = false;
  refreshState: any;
  productos: Producto[] = [];
  loading = true;
  totalItems = 0;
  constructor(private productoService: ProductosService) {}

  ngOnInit(): void {}

  /* #region Events */

  refresh(state: ClrDatagridStateInterface<Producto>) {
    this.loading = true;
    if (state != null) this.refreshState = state;
    else state = this.refreshState;
    let orderBy = '';
    let orderReverse = false;
    let from = 0;
    let size = 100;
    if (state.sort) {
      orderBy = state.sort.by.toString();
      orderReverse = state.sort.reverse;
    }
    if (state.page?.from) {
      from = state.page.from;
    }
    if (state.page?.size) {
      size = state.page.size;
    }
    this.productoService
      .getDatagrid(from, size, orderBy, orderReverse, this.onlyActivos)
      .subscribe(
        (result: DatagridContainer<Producto>) => {
          if (result.data != undefined && result.totalItems != undefined) {
            this.productos = result.data;
            this.totalItems = result.totalItems;
          } else {
            this.productos = [];
            this.totalItems = 0;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          this.loading = false;
        }
      );
  }

  onChangeActivos() {
    this.refresh(this.refreshState);
  }

  onSearch() {}

  onClearSearch() {}

  /* #region Producto Edit */

  inputProductoEdit: InputProductoEdit = undefined;

  onOpenProductoEdit(productoId: number) {
    this.inputProductoEdit = { productoId: productoId };
  }

  onCloseProductoEdit(refresh: any) {
    this.refresh(this.refreshState);
    this.inputProductoEdit = undefined;
  }

  /* #endregion Producto Edit */

  /* #region Producto New */

  inputProductoNew: boolean = false;

  onOpenProductoNew() {
    this.inputProductoNew = true;
  }

  onCloseProductoNew(outputProductoNew: OutputProductoNew) {
    if (outputProductoNew.refresh)
      this.refresh(this.refreshState);
    this.inputProductoNew = false;
    if(outputProductoNew.productoId != undefined)
      this.inputProductoEdit = { productoId: outputProductoNew.productoId };
  }

  /* #endregion Producto New */

  /* #region Producto Masivos */

inputProductoMasivos: boolean = false;

onOpenProductoMasivos() {
  this.inputProductoMasivos = true;
}

onCloseProductoMasivos() {
  this.inputProductoMasivos = false;
}

/* #endegion Producto New */

  /* #endregion Events */
}
