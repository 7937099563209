import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-productos-edit-masivos-precio',
  templateUrl: './productos-edit-masivos-precio.component.html',
  styleUrls: ['./productos-edit-masivos-precio.component.css']
})
export class ProductosEditMasivosPrecioComponent implements OnInit {
  @Input() inputProductosEditMasivosPrecio: InputProductosEditMasivosPrecio;
  @Output() onCloseEventEmitter = new EventEmitter<OutputProductosEditMasivosPrecio>();
  porcentajeAumento: number = 0;
  constructor() { }

  ngOnInit(): void {
  }

  onClose() {
    this.onCloseEventEmitter.emit({ aplicar: false });
  }

  onApply() {
    this.onCloseEventEmitter.emit({ aplicar: true, porcentajeAumento: this.porcentajeAumento });
  }

}

export class InputProductosEditMasivosPrecio {
  prodfuctosSeleccionados = 0;
}

export class OutputProductosEditMasivosPrecio {
  aplicar: boolean;
  porcentajeAumento?: number;
}
