import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClrForm, ClrLoadingState } from '@clr/angular';
import { ToastrService } from 'ngx-toastr';
import { Configuracion } from 'src/app/shared/models/configuracion.model';
import { ConfiguracionService } from '../../configuracion.service';
import { ResponseMessage } from 'src/app/shared/models/response-message.model';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.css'],
})
export class ConfiguracionComponent implements OnInit {
  @ViewChild(ClrForm, { static: true }) clrForm;
  saveBtn: ClrLoadingState = ClrLoadingState.DEFAULT;
  form: FormGroup = this.formBuilder.group({});
  configuraciones: Configuracion[] = []
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private configuracionService: ConfiguracionService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      recargoMP: [ { value: 0 }, [Validators.required, Validators.min(0), Validators.max(100)],],
      montoMinimo: [{ value: 0 }, [Validators.required, Validators.min(0), Validators.max(9999999)], ],
      iva: [ { value: 0 }, [Validators.required, Validators.min(0), Validators.max(100)], ],
      monto1: [ { value: 0 }, [Validators.required, Validators.min(0), Validators.max(9999999)], ],
      descuento1: [ { value: 0 }, [Validators.required, Validators.min(0), Validators.max(100)], ],
      monto2: [ { value: 0 }, [Validators.required, Validators.min(0), Validators.max(9999999)], ],
      descuento2: [ { value: 0 }, [Validators.required, Validators.min(0), Validators.max(100)], ],
    });
    this.configuracionService.getConfiguracion().subscribe((result: Configuracion[]) => {
      this.configuraciones = result;
      this.form.patchValue({
          recargoMP: this.configuraciones.find(e => e.nombre == 'RecargoMercadoPago').valor,
          montoMinimo: this.configuraciones.find(e => e.nombre == 'MontoMinimoCompra').valor,
          iva: this.configuraciones.find(e => e.nombre == 'IVA').valor,
          monto1: this.configuraciones.find(e => e.nombre == 'OrdenMinimaMonto1').valor,
          descuento1: this.configuraciones.find(e => e.nombre == 'OrdenMinimaDescuento1').valor,
          monto2: this.configuraciones.find(e => e.nombre == 'OrdenMinimaMonto2').valor,
          descuento2: this.configuraciones.find(e => e.nombre == 'OrdenMinimaDescuento2').valor,
        });
    });
  }

  onSave() {
    if (this.form.invalid) {
      this.clrForm.markAsTouched();
      return;
    } else  {
      this.saveBtn = ClrLoadingState.LOADING;
      this.configuraciones.find(e => e.nombre == 'RecargoMercadoPago').valor = this.form.get('recargoMP').value,
      this.configuraciones.find(e => e.nombre == 'MontoMinimoCompra').valor = this.form.get('recargoMP').value,
      this.configuraciones.find(e => e.nombre == 'IVA').valor = this.form.get('iva').value,
      this.configuraciones.find(e => e.nombre == 'OrdenMinimaMonto1').valor = this.form.get('monto1').value,
      this.configuraciones.find(e => e.nombre == 'OrdenMinimaDescuento1').valor = this.form.get('descuento1').value,
      this.configuraciones.find(e => e.nombre == 'OrdenMinimaMonto2').valor = this.form.get('monto2').value,
      this.configuraciones.find(e => e.nombre == 'OrdenMinimaDescuento2').valor = this.form.get('descuento2').value
      this.configuracionService.updateInformacion(this.configuraciones).subscribe((result: ResponseMessage<any>) => {
        if(result.success) {
          this.toastr.success("Se actualizo la configuración");
          this.saveBtn = ClrLoadingState.SUCCESS;
        } else {
          this.toastr.error(result.message);
          this.saveBtn = ClrLoadingState.ERROR;
        }
      });
    }
  }
}
