import { Component, OnInit } from '@angular/core';
import { ContactosService } from '../../contactos.service';
import { Contacto } from 'src/app/shared/models/contacto.model';

@Component({
  selector: 'app-contactos',
  templateUrl: './contactos.component.html',
  styleUrls: ['./contactos.component.css']
})
export class ContactosComponent implements OnInit {
  contactos: Contacto[] = [];
  constructor(private contactoService:ContactosService) { }

  ngOnInit(): void {
    this.contactoService.get().subscribe((result : Contacto[]) => {
      this.contactos = result;
    });
  }
}
