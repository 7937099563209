import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductosService } from '../../productos.service';
import { ProductoPresentacion } from 'src/app/shared/models/producto-presentacion.model';
import { ClrForm, ClrLoadingState } from '@clr/angular';
import { ResponseMessage } from 'src/app/shared/models/response-message.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-productos-edit-presentaciones-edit',
  templateUrl: './productos-edit-presentaciones-edit.component.html',
  styleUrls: ['./productos-edit-presentaciones-edit.component.css'],
})
export class ProductosEditPresentacionesEditComponent implements OnInit {
  @Input() inputPresentacionEdit: InputPresentacionEdit;
  @Output() onCloseEventEmitter = new EventEmitter<boolean>();
  @ViewChild(ClrForm) clrForm;
  saveBtn: ClrLoadingState = ClrLoadingState.DEFAULT;
  loadingWindow = true;
  formGroup;
  constructor(private productoService: ProductosService, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      id: new FormControl(),
      codigo: new FormControl('', Validators.required),
      descripcion: new FormControl('', Validators.required),
      precioId: new FormControl(),
      precio: new FormControl('', [Validators.required, Validators.min(0)]),
      stock: new FormControl('', [Validators.required, Validators.min(0)]),
    });
    this.productoService
      .getPresentacion(this.inputPresentacionEdit.productoPresentacionId)
      .subscribe((result: ProductoPresentacion) => {
        this.formGroup.patchValue({
          id: result.id,
          codigo: result.codigo,
          descripcion: result.descripcion,
          precioId: result.precioId,
          precio: result.precio,
          stock: result.stock
        });
        this.loadingWindow = false;
      });
  }

  onClose() {
    this.onCloseEventEmitter.emit(false);
  }

  onSave() {
    if (this.formGroup.invalid) {
      this.clrForm.markAsTouched();
    } else {
      this.saveBtn = ClrLoadingState.LOADING;
      this.productoService
        .updatePresentacion(
          this.inputPresentacionEdit.productoPresentacionId,
          this.formGroup.get('codigo').value,
          this.formGroup.get('descripcion').value,
          Number(this.formGroup.get('precioId').value),
          Number(this.formGroup.get('precio').value),
          Number(this.formGroup.get('stock').value)
        )
        .subscribe(
          (result: ResponseMessage<any>) => {
            if (result.success) {
              this.toastr.success("Se actualizó la presentación.");
              this.onCloseEventEmitter.emit(true);
            } else {
              this.toastr.error(result.message);
            }
          },
          () => {
            this.saveBtn = ClrLoadingState.DEFAULT;
          }
        );
    }
  }
}
export class InputPresentacionEdit {
  productoPresentacionId: number;
}
