import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClrForm, ClrLoadingState } from '@clr/angular';
import { ProductosService } from '../../productos.service';
import { ProductoGeneral } from 'src/app/shared/models/producto-general.model';
import { ResponseMessage } from 'src/app/shared/models/response-message.model';

@Component({
  selector: 'app-productos-edit',
  templateUrl: './productos-edit.component.html',
  styleUrls: ['./productos-edit.component.css']
})
export class ProductosEditComponent implements OnInit {
  @Input() inputProductoEdit: InputProductoEdit;
  @Output() onCloseEventEmitter = new EventEmitter<boolean>();

  constructor(private productoServideo: ProductosService) { }

  ngOnInit(): void {

  }

  onClose() {
    this.onCloseEventEmitter.emit(true);
  }
}

export class InputProductoEdit {
  productoId: number;
}

