import { Component, Input, OnInit } from '@angular/core';
import { PedidoItem } from 'src/app/shared/models/pedido-item.model';
import { Pedido } from 'src/app/shared/models/pedido.model';
import { PedidosService } from '../../pedidos.service';

@Component({
  selector: 'app-pedidos-detalle',
  templateUrl: './pedidos-detalle.component.html',
  styleUrls: ['./pedidos-detalle.component.css']
})
export class PedidosDetalleComponent implements OnInit {
  @Input() pedidoId: number;
  loading: boolean;
  pedido : Pedido = {};

  constructor(private pedidoService: PedidosService) { }

  ngOnInit(): void {
    this.loading = true;
    this.pedidoService.getPedido(this.pedidoId).subscribe((result: Pedido) => {
      this.pedido = result;
      this.loading = false;
    })
  }

}
