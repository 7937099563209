import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClrLoadingState } from '@clr/angular';
import { ProductosService } from '../../productos.service';
import { ProductoPresentacion } from 'src/app/shared/models/producto-presentacion.model';
import { InputPresentacionEdit } from '../productos-edit-presentaciones-edit/productos-edit-presentaciones-edit.component';

@Component({
  selector: 'app-productos-edit-presentaciones',
  templateUrl: './productos-edit-presentaciones.component.html',
  styleUrls: ['./productos-edit-presentaciones.component.css'],
})
export class ProductosEditPresentacionesComponent implements OnInit {
  @Input() productoId: number;
  @Output() onCloseEventEmitter = new EventEmitter<boolean>();
  saveBtn: ClrLoadingState = ClrLoadingState.DEFAULT;
  productoPresentaciones: ProductoPresentacion[];
  loadingWindow = true;
  constructor(private productoService: ProductosService) {}

  ngOnInit(): void {
    this.getPresentaciones();
  }

  getPresentaciones() {
    this.productoService
      .getPresentaciones(this.productoId)
      .subscribe((result: ProductoPresentacion[]) => {
        console.log(result);
        this.productoPresentaciones = result;
      });
  }

  /* #region Presentacion */

  onClose() {
    this.onCloseEventEmitter.emit(false);
  }

  /* #endregion Presentacion */

  /* #region Presentacion New */
  inputPresentacionAdd: boolean | undefined = undefined;

  onShowPresentacionAdd() {
    this.inputPresentacionAdd = true;
  }

  onClosePresentacionAdd(refresh) {
    if(refresh)
      this.getPresentaciones();
    this.inputPresentacionAdd = undefined;
  }
  /* #endregion Presentacion New */

  /* #region Presentacion Edit */
  inputPresentacionEdit: InputPresentacionEdit = undefined;

  onShowPresentacionEdit(productoPresentacionId: number) {
    this.inputPresentacionEdit = {
      productoPresentacionId: productoPresentacionId,
    };
  }

  onClosePresentacionEdit(refresh) {
    if(refresh)
      this.getPresentaciones();
    this.inputPresentacionEdit = undefined;
  }
  /* #endregion Presentacion Edit */
}
