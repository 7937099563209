import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactosComponent } from './pages/contactos/contactos.component';
import { ClarityModule } from '@clr/angular';

@NgModule({
  declarations: [
    ContactosComponent
  ],
  imports: [
    CommonModule, ClarityModule
  ]
})
export class ContactosModule { }
