import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {
  searchPattern: string = '';
  constructor() { }

  ngOnInit(): void {
  }

  /* #region Events */

  onSearch() {

  }

  onClearSearch() {

  }

  /* #endregion Events */
}
