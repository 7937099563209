import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CuitValidator } from 'src/app/shared/helpers/cuit-validator';
import { Cliente } from 'src/app/shared/models/cliente.model';
import { ResponseMessage } from 'src/app/shared/models/response-message.model';
import { ClienteService } from '../../cliente.service';
import { ClrForm } from '@clr/angular';

@Component({
  selector: 'app-cliente-edit',
  templateUrl: './cliente-edit.component.html',
  styleUrls: ['./cliente-edit.component.css'],
})
export class ClienteEditComponent implements OnInit {
  @Output() onCloseEventEmitter = new EventEmitter<boolean>();
  @Input() inputClienteEdit: InputClienteEdit;
  @ViewChild(ClrForm, { static: true }) clrForm;
  form: FormGroup = this.formBuilder.group({});
  submitted: boolean = false;
  cliente: Cliente | undefined = undefined;
  constructor(
    private formBuilder: FormBuilder,
    private clienteService: ClienteService,
    private toastr: ToastrService
  ) {}

  /* #region Events */

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      usuarioId: [],
      tipoCliente: [{ value: '' }],
      nombreCliente: [{ value: '' }],
      tipoDocumentoFiscal: [''],
      documentoFiscal: ['', [Validators.required, Validators.minLength(11), CuitValidator.cuit]],
      whatsapp: [''],
      instagram: [''],
      web: [''],
      nombre: [{ value: '' }, [Validators.required, Validators.maxLength(128)]],
      apellido: [{ value: '' },[Validators.required, Validators.maxLength(128)]],
      correoElectronico: [{ value: '', disabled: true }],
      domicilio: [{ value: ''}, [Validators.required, Validators.maxLength(1024)]],
      ciudad: [{ value: ''}, [Validators.required, Validators.maxLength(1024)]],
      codigoPostal: [{ value: '' }, [Validators.required, Validators.maxLength(12)]],
      provincia: {value: ''},
      pais: { value: '' },
      verificado: [{ value: false }],
      activo: [{ value: false }],
      tipoUsuario: [{ value: '' }],
    });
    this.clienteService
      .getById(this.inputClienteEdit.clienteId)
      .subscribe((result: Cliente) => {
        if (result != undefined) {
          this.form.patchValue({
            usuarioId: result.usuarioId,
            tipoCliente: result.tipoCliente,
            nombreCliente: result.nombreCliente,
            tipoDocumentoFiscal: result.tipoDocumentoFiscal,
            documentoFiscal: result.documentoFiscal,
            whatsapp: result.whatsapp,
            instagram: result.instagram,
            correoElectronico: result.correoElectronico,
            web: result.web,
            nombre: result.nombre,
            apellido: result.apellido,
            domicilio: result.domicilio,
            ciudad: result.ciudad,
            codigoPostal: result.codigoPostal,
            provincia: result.provinciaId,
            pais: result.paisId,
            activo: result.activo,
            verificado: result.verificado,
            tipoUsuario: result.tipoUsuario
          });
          this.cliente = result;
        }
      });
  }

  onSave() {
    if (this.form.invalid) {
      this.clrForm.markAsTouched();
      let errorMessage = '';
      if(this.form.controls['documentoFiscal'].invalid)
        errorMessage += "<li>Documento Fiscal</li>";
      if(this.form.controls['nombre'].invalid)
        errorMessage += "<li>Nombre</li>";
      if(this.form.controls['apellido'].invalid)
        errorMessage += "<li>Apellido</li>";
      if(this.form.controls['domicilio'].invalid)
        errorMessage += "<li>Domicilio</li>";
      if(this.form.controls['ciudad'].invalid)
        errorMessage += "<li>Ciudad</li>";
      if(this.form.controls['codigoPostal'].invalid)
        errorMessage += "<li>Codigo Postal</li>";
      this.toastr.error(errorMessage,"Campos obligatorios pendientes", { closeButton: true, timeOut: 5000, progressBar: true, enableHtml: true });
      return;
    } else {
    this.clienteService
      .put(
        this.inputClienteEdit.clienteId,
        Number(this.form.controls['usuarioId'].value),
        Number(this.form.controls['tipoCliente'].value),
        this.form.controls['nombreCliente'].value,
        Number(this.form.controls['tipoDocumentoFiscal'].value),
        this.form.controls['documentoFiscal'].value,
        this.form.controls['whatsapp'].value,
        this.form.controls['web'].value,
        this.form.controls['instagram'].value,
        this.form.controls['nombre'].value,
        this.form.controls['apellido'].value,
        this.form.controls['activo'].value,
        this.form.controls['domicilio'].value,
        this.form.controls['ciudad'].value,
        this.form.controls['codigoPostal'].value,
        Number(this.form.controls['provincia'].value),
        Number(this.form.controls['pais'].value),
        Number(this.form.controls['tipoUsuario'].value),
      )
      .subscribe((result: ResponseMessage<any>) => {
        this.toastr.success(
          'Se ha actualizado el cliente.',
          '¡Actualización exitosa!'
        );
        this.onCloseEventEmitter.emit(true);
      });
    }
  }

  onClose(refresh: boolean) {
    this.onCloseEventEmitter.emit(refresh);
  }

  /* #endegion Events */
}

export class InputClienteEdit {
  clienteId: number;
}
