<div class="clr-row">
  <div class="clr-col-12">
    <h2 class="title-page">
      <clr-icon shape="details" size="28"></clr-icon> Contactos
    </h2>
  </div>
</div>
<table class="table">
  <thead>
      <tr>
          <th>Fecha</th>
          <th>Nombre</th>
          <th>Nro. Whatsapp</th>
          <th>Correo eléctronico</th>
          <th>Consulta</th>
      </tr>
  </thead>
  <tbody>
      <tr *ngFor="let contacto of contactos">
          <td class="left column-fecha">{{contacto.fecha | date: 'dd/MM/yyyy hh:mm'}}</td>
          <td class="left column-nombre">{{contacto.nombre}}</td>
          <td class="left">{{contacto.whatsapp}}</td>
          <td class="left">{{contacto.correoElectronico}}</td>
          <td class="left">{{contacto.consulta}}</td>
      </tr>
  </tbody>
</table>


