import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Producto } from 'src/app/shared/models/producto.model';
import { environment } from 'src/environments/environment';
import { ProductosService } from '../../productos.service';
import { ResponseMessage } from 'src/app/shared/models/response-message.model';

@Component({
  selector: 'app-productos-edit-fotos',
  templateUrl: './productos-edit-fotos.component.html',
  styleUrls: ['./productos-edit-fotos.component.css']
})
export class ProductosEditFotosComponent implements OnInit {
  @Input() productoId: number;
  @ViewChild('attach') attachInput;
  productoUuid;
  loading = true;
  @Output() onCloseEventEmitter = new EventEmitter<boolean>();
  randomImage;
  constructor(private productoService: ProductosService) { }

  ngOnInit(): void {
    this.randomImageGenerator();
    this.productoService.getProductoUuid(this.productoId).subscribe((result: ResponseMessage<string>) => {
      this.productoUuid = result.entity;
      this.loading = false;
    })
  }

  getUrlImagen(version) {
    const url = `${environment.staticUrl}${this.productoUuid}-${version}.png?key=${this.randomImage}`;
    return url;
  }

  onClose() {
    this.onCloseEventEmitter.emit(false);
  }

  onAttachFile1() {
    this.onAttachFile(1);
  }

  onAttachFile2() {
    this.onAttachFile(2);
  }

  onAttachFile(tipoArchivo) {
    const fileInput = this.attachInput.nativeElement;
    fileInput.onchange = () => {
      const formatSupported = [ ];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const archivo = e.target.result;
        const nombreArchivo = fileInput.files[0].name;
        this.productoService.updateImagen(archivo, nombreArchivo, this.productoUuid, tipoArchivo).subscribe((result: ResponseMessage<any>) => {
          this.randomImageGenerator();
         });
      }
      reader.readAsDataURL(fileInput.files[0]);
    };
    fileInput.click();
  }

  private randomImageGenerator() {
    this.randomImage = Math.floor(Math.random() * (999999 - 1)) + 1;
  }
}
