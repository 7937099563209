import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClienteComponent } from './pages/cliente/cliente.component';
import { ClarityModule } from '@clr/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClienteEditComponent } from './components/cliente-edit/cliente-edit.component';

@NgModule({
  declarations: [
    ClienteComponent,
    ClienteEditComponent
  ],
  imports: [
    CommonModule, ClarityModule, FormsModule, ReactiveFormsModule
  ]
})
export class ClienteModule { }
