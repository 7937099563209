import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PedidosComponent } from './pages/pedidos/pedidos.component';
import { ClarityModule } from '@clr/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PedidosDetalleComponent } from './components/pedidos-detalle/pedidos-detalle.component';
import { PedidosEditComponent } from './components/pedidos-edit/pedidos-edit.component';



@NgModule({
  declarations: [
    PedidosComponent,
    PedidosDetalleComponent,
    PedidosEditComponent
  ],
  imports: [
    CommonModule, ClarityModule, FormsModule, ReactiveFormsModule
  ]
})
export class PedidosModule { }
