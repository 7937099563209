<clr-modal [clrModalOpen]="true" [clrModalClosable]="false" class="modal-line-height" [clrModalSize]="'m'">
  <h3 class="modal-title">Incremento de precios</h3>
  <div class="modal-body">

      <form clrForm>
        <clr-input-container>
          <label>%</label>
          <input clrInput name="porcentaje" [(ngModel)]="porcentajeAumento" type="number" />
        </clr-input-container>
      </form>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="onClose()">Cerrar</button>
    <button type="button" class="btn btn-primary" (click)="onApply()" [disabled]="porcentajeAumento < 1">Aplicar</button>
  </div>
</clr-modal>
