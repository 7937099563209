<clr-modal [clrModalOpen]="true" [clrModalClosable]="false">
  <h3 class="modal-title">Editar pedido</h3>
  <div class="modal-body">
    <form
    clrForm
    clrLayout="horizontal"
    clrLabelSize="4"
    [formGroup]="form"
  >
    <clr-select-container>
      <label>Estado</label>
      <select clrSelect name="options" formControlName="estado">
        <option value="1">Pendiente de cobro</option>
        <option value="2">En proceso</option>
        <option value="3">En camino</option>
        <option value="4">Entregado</option>
        <option value="99">Cancelado</option>
      </select>
    </clr-select-container>
    <clr-textarea-container>
      <label>Observaciones</label>
      <textarea clrTextarea name="observaciones" formControlName="observaciones" required></textarea>
    </clr-textarea-container>
    </form>
  </div>
  <div class="modal-footer">
      <button type="button" (click)="onCloseEdit(false)" class="btn btn-outline">Cerrar</button>
      <button type="button" class="btn btn-primary" (click)="onSave()" [disabled]="form?.controls.estado.value == pedido?.estado">Grabar</button>
  </div>
</clr-modal>
