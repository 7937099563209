<div class="modal-container">
  <div class="clr-row">
  <div class="clr-col-12">
    <button class="btn btn-sm btn-primary" style="float: right;" (click)="onShowPresentacionAdd()">
      <clr-icon shape="plus-circle"></clr-icon> Nuevo
    </button>
  </div>
</div>
  <table class="table table-compact">
    <thead>
        <tr>
            <th class="left col-codigo">Código</th>
            <th class="left">Descripción</th>
            <th class="left coll-stock">Stock</th>
            <th class="left col-precio">Precio</th>
            <th class="left col-command"></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let productoPresentacion of productoPresentaciones">
            <td class="left col-codigo">{{productoPresentacion.codigo}}</td>
            <td class="left">{{productoPresentacion.descripcion}}</td>
            <td class="right col-stock">{{productoPresentacion.stock}}</td>
            <td class="right col-precio">{{productoPresentacion.precio | currency}}</td>
            <td class="left col-command">
              <clr-icon class="datagrid-edit-icon" title="Editar" shape="note" (click)="onShowPresentacionEdit(productoPresentacion.id)"></clr-icon>
            </td>
        </tr>
    </tbody>
  </table>
</div>
<div class="clr-row">
  <div class="clr-col-12">
    <button type="button" class="btn" (click)="onClose()" style="float: right;">Cerrar</button>
  </div>
</div>
<app-productos-edit-presentaciones-add *ngIf="inputPresentacionAdd" [inputProductoEditId]="productoId" (onCloseEventEmitter)="onClosePresentacionAdd($event)"></app-productos-edit-presentaciones-add>
<app-productos-edit-presentaciones-edit *ngIf="inputPresentacionEdit" [inputPresentacionEdit]="inputPresentacionEdit" (onCloseEventEmitter)="onClosePresentacionEdit($event)"></app-productos-edit-presentaciones-edit>
