import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClrForm, ClrLoadingState } from '@clr/angular';
import { ProductosService } from '../../productos.service';
import { ToastrService } from 'ngx-toastr';
import { ProductoGeneral } from 'src/app/shared/models/producto-general.model';
import { ResponseMessage } from 'src/app/shared/models/response-message.model';

@Component({
  selector: 'app-productos-edit-general',
  templateUrl: './productos-edit-general.component.html',
  styleUrls: ['./productos-edit-general.component.css']
})
export class ProductosEditGeneralComponent implements OnInit {
  @Input() productoId: number;
  @Output() onCloseEventEmitter = new EventEmitter<boolean>();
  @ViewChild(ClrForm, { static: true }) clrForm;
  saveBtn: ClrLoadingState = ClrLoadingState.DEFAULT;
  loadingWindow = true;
  formGroup;
  constructor(private productoService: ProductosService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      marca: new FormControl('Plagron'),
      nombre: new FormControl('',Validators.required),
      resumen: new FormControl('',Validators.required),
      descripcion: new FormControl(''),
      dosificacion: new FormControl(''),
      activo: new FormControl('')
    });
    this.productoService.getGeneral(this.productoId).subscribe((result: ProductoGeneral) =>{
      this.formGroup.patchValue({ marca: result.marca, nombre: result.nombre, resumen: result.resumen, descripcion: result.descripcion, dosificacion: result.dosificacion, activo: result.activo });
    });
    this.loadingWindow = false;
  }

  onClose() {
    this.onCloseEventEmitter.emit(false);
  }

  onSave() {
    if (this.formGroup.invalid) {
      this.clrForm.markAsTouched();
      return;
    }
    else {
      this.saveBtn = ClrLoadingState.LOADING;
      this.productoService.updateProducto(this.productoId, this.formGroup.get('marca').value, this.formGroup.get('nombre').value,
      this.formGroup.get('resumen').value, this.formGroup.get('descripcion').value, this.formGroup.get('dosificacion').value, this.formGroup.get('activo').value).subscribe((result: ResponseMessage<any>) => {
        if(result.success) {
          this.toastr.success("Se actualizó el producto.");
        } else {
          this.toastr.error(result.message);
        }
      }, error => null, () => {
        this.saveBtn = ClrLoadingState.DEFAULT;
      });
    }
  }
}
