<clr-modal
  [clrModalOpen]="true"
  [clrModalClosable]="false"
  class="modal-line-height"
>
  <h3 class="modal-title">Agregar ventaja</h3>
  <div class="modal-body">
    <div>
      <form clrForm clrLayout="horizontal" [formGroup]="formGroup">
        <clr-input-container>
          <label>Ventaja</label>
          <input
            clrInput
            type="text"
            name="ventaja"
            formControlName="ventaja"
          />
          <clr-control-error
            >El campo ventaja es requerido.</clr-control-error
          >
        </clr-input-container>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="onClose()" [disabled]="saveBtn == 1">Cerrar</button>
    <button type="button" class="btn btn-primary" (click)="onSave()" [clrLoading]="saveBtn">Grabar</button>
  </div>
</clr-modal>

