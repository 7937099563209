<div class="main-container">
  <header class="header-6 header-white">
    <img src="../.././../../assets/logo.png" />
    <div class="header-actions">
      <a href="javascript://" class="nav-link nav-icon" aria-label="settings">
        <clr-icon class="nav-text-gray" shape="cog"></clr-icon>
      </a>
    </div>
  </header>
  <div class="content-container">
    <clr-vertical-nav [clrVerticalNavCollapsible]="true" >
      <a clrVerticalNavLink routerLink="./pedidos" routerLinkActive="active">
        <clr-icon shape="shopping-cart" class="is-solid" clrVerticalNavIcon></clr-icon>
        Pedidos
      </a>
      <a clrVerticalNavLink routerLink="./productos" routerLinkActive="active">
        <clr-icon shape="blocks-group" class="is-solid" clrVerticalNavIcon></clr-icon>
        Productos
      </a>
      <a clrVerticalNavLink routerLink="./clientes" routerLinkActive="active">
        <clr-icon shape="employee" class="is-solid" clrVerticalNavIcon></clr-icon>
        Clientes
      </a>
      <!-- <a clrVerticalNavLink routerLinkActive="active">
        <clr-icon shape="user" class="is-solid" clrVerticalNavIcon></clr-icon>
        Usuarios
      </a> -->
      <a clrVerticalNavLink routerLink="./contactos" routerLinkActive="active">
        <clr-icon shape="details" class="is-solid" clrVerticalNavIcon></clr-icon>
        Contactos
      </a>
      <a clrVerticalNavLink routerLink="./configuracion" routerLinkActive="active">
        <clr-icon shape="cog" class="is-solid" clrVerticalNavIcon></clr-icon>
        Configuración
      </a>
    </clr-vertical-nav>
    <div class="content-area">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
