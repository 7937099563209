<div class="clr-row">
  <div class="clr-col-6">
    <h2 class="title-page">
      <clr-icon shape="cog" class="is-solid" size="28"></clr-icon> Configuración
    </h2>
  </div>
</div>
<hr />
<form clrForm clrLayout="horizontal" clrLabelSize="4" [formGroup]="form">
  <span class="p2">Recargo Mercado Pago</span>
  <hr/>
  <div class="clr-row">
    <div class="clr-col-lg-6 clr-col-sm-12">
      <clr-input-container>
        <label>Recargo %</label>
        <input
          clrInput
          type="number"
          name="recargoMP"
          maxlength="5"
          formControlName="recargoMP"
          class="input-50"
        />
        <clr-control-error>Debe ingresar el porcentaje de recargo.</clr-control-error>
      </clr-input-container>
    </div>
  </div>
  <br/>
  <span class="p2">Monto minimo de compra</span>
  <hr/>
  <div class="clr-row">
    <div class="clr-col-lg-6 clr-col-sm-12">
      <clr-input-container>
        <label>Monto $</label>
        <input
          clrInput
          type="number"
          name="montoMinimo"
          maxlength="7"
          formControlName="montoMinimo"
          class="input-50"
        />
        <clr-control-error>Debe ingresar un monto minimo.</clr-control-error>
      </clr-input-container>
    </div>
  </div>
  <br/>
  <span class="p2">Porcentaje de IVA</span>
  <hr/>
  <div class="clr-row">
    <div class="clr-col-lg-6 clr-col-sm-12">
      <clr-input-container>
        <label>IVA %</label>
        <input
          clrInput
          type="number"
          name="iva"
          maxlength="5"
          formControlName="iva"
          class="input-50"
        />
        <clr-control-error>Debe ingresar el porcentaje de IVA</clr-control-error>
      </clr-input-container>
    </div>
  </div>
  <br/>
  <span class="p2">Orden minima</span>
  <hr/>
  <div class="clr-row">
    <div class="clr-col-lg-6 clr-col-sm-12">
      <clr-input-container>
        <label>Monto $</label>
        <input
          clrInput
          type="number"
          name="monto1"
          maxlength="5"
          formControlName="monto1"
          class="input-50"
        />
        <clr-control-error>Debe ingresar un monto</clr-control-error>
      </clr-input-container>
      </div>
      <div class="clr-col-lg-6 clr-col-sm-12">
      <clr-input-container>
        <label>Descuento %</label>
        <input
          clrInput
          type="number"
          name="descuento1"
          maxlength="5"
          formControlName="descuento1"
          class="input-50"
        />
        <clr-control-error>Debe ingresar el porc. de descuento</clr-control-error>
      </clr-input-container>
    </div>
  </div>
  <!-- <div class="clr-row">
    <div class="clr-col-lg-6 clr-col-sm-12">
      <clr-input-container>
        <label>Monto 2 $</label>
        <input
          clrInput
          type="number"
          name="iva"
          maxlength="5"
          formControlName="monto2"
          class="input-50"
        />
        <clr-control-error>Debe ingresar un monto</clr-control-error>
      </clr-input-container>
      </div>
      <div class="clr-col-lg-6 clr-col-sm-12">
      <clr-input-container>
        <label>Descuento 2 %</label>
        <input
          clrInput
          type="number"
          name="iva"
          maxlength="5"
          formControlName="descuento2"
          class="input-50"
        />
        <clr-control-error>Debe ingresar el porc. de descuento</clr-control-error>
      </clr-input-container>
    </div>
  </div> -->
  <div class="clr-row clr-row-button">
    <div class="clr-col-lg-12 clr-col-sm-12">
      <button type="button" class="btn btn-primary" style="float:right" [clrLoading]="saveBtn" (click)="onSave()">Grabar</button>
    </div>
  </div>
</form>
