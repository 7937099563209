import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClrForm, ClrLoadingState } from '@clr/angular';
import { ProductosService } from '../../productos.service';
import { ResponseMessage } from 'src/app/shared/models/response-message.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-productos-edit-presentaciones-add',
  templateUrl: './productos-edit-presentaciones-add.component.html',
  styleUrls: ['./productos-edit-presentaciones-add.component.css'],
})
export class ProductosEditPresentacionesAddComponent implements OnInit {
  @Input() inputProductoEditId: number;
  @Output() onCloseEventEmitter = new EventEmitter<boolean>();
  @ViewChild(ClrForm) clrForm;
  saveBtn: ClrLoadingState = ClrLoadingState.DEFAULT;
  formGroup;
  constructor(private productoService: ProductosService, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      codigo: new FormControl('', Validators.required),
      descripcion: new FormControl('', Validators.required),
      precio: new FormControl('', [Validators.required, Validators.min(0)]),
      stock: new FormControl('', [Validators.required, Validators.min(0)]),
    });
  }

  onClose() {
    this.onCloseEventEmitter.emit(true);
  }

  onSave() {
    if (this.formGroup.invalid) {
      this.clrForm.markAsTouched();
    } else {
      this.saveBtn = ClrLoadingState.LOADING;
      this.productoService
        .createPresentacion(
          this.inputProductoEditId,
          this.formGroup.get('codigo').value,
          this.formGroup.get('descripcion').value,
          Number(this.formGroup.get('precio').value),
          Number(this.formGroup.get('stock').value)
        )
        .subscribe(
          (result: ResponseMessage<any>) => {
            if (result.success) {
              this.toastr.success("Se agregó la presentación.");
              this.onCloseEventEmitter.emit(true);
            } else {
              this.toastr.error(result.message);
            }
          },
          () => {
            this.saveBtn = ClrLoadingState.DEFAULT;
          }
        );
    }
  }
}
