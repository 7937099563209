<div class="clr-row">
  <div class="clr-col-6">
    <h2 class="title-page">
      <clr-icon shape="user" size="28"></clr-icon> Usuarios
    </h2>
  </div>
  <div class="clr-col-6">
    <button class="btn btn-primary button-nuevo">
      <clr-icon shape="plus-circle"></clr-icon> Nuevo
    </button>
  </div>
</div>
<hr />
<div class="clr-row">
  <div class="clr-col-lg-5 clr-col-12 search-col">
    <div class="search-container">
      <label for="search_input" class="label-search">
        <clr-icon shape="filter-grid" class="is-solid" size="22"></clr-icon>
        <input
          id="search_input"
          class="clr-input clr-input-75"
          type="text"
          [(ngModel)]="searchPattern"
          placeholder=""
        />
        <clr-icon
          (click)="onClearSearch()"
          shape="close"
          class="click-icon"
        ></clr-icon>
        <clr-icon
          (click)="onSearch()"
          shape="search"
          class="click-icon"
        ></clr-icon>
      </label>
    </div>
  </div>
  <div class="clr-col-lg-5 clr-col-12">
    <clr-select-container>
      <label>Estado</label>
      <select
        clrSelect
        name="empresasOptions"
      >
        <option value="">Todas</option>
      </select>
    </clr-select-container>
  </div>
  <div class="clr-col-lg-2 clr-col-12">
  </div>
</div>


