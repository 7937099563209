import { Component, OnInit } from '@angular/core';
import { ClrDatagridStateInterface } from '@clr/angular';
import { Cliente } from 'src/app/shared/models/cliente.model';
import { DatagridContainer } from 'src/app/shared/models/datagrid-container.model';
import { ClienteService } from '../../cliente.service';
import { InputClienteEdit } from '../../components/cliente-edit/cliente-edit.component';

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.css']
})
export class ClienteComponent implements OnInit {
  loading = true;
  refreshState: any;
  searchPattern = '';
  clientes: Cliente[] = [];
  totalItems: number = 0;
  clienteIdSelected: number | undefined = undefined;
  constructor(private clienteService: ClienteService) { }

  ngOnInit(): void {
  }

  /* #region Events */

  refresh(state: ClrDatagridStateInterface<Cliente>) {
    this.loading = true;
    if (state != null) this.refreshState = state;
    else state = this.refreshState;
    let orderBy = '';
    let orderReverse = false;
    let from = 0;
    let size = 25;
    if (state.sort) {
      orderBy = state.sort.by.toString();
      orderReverse = state.sort.reverse;
    }
    if(state.page?.from) {
      from = state.page.from;
    }
    if(state.page?.size) {
      size = state.page.size;
    }
    if(from === -1)
      from = 0;
    this.clienteService
      .getDatagrid(from, size, orderBy, orderReverse)
      .subscribe(
        (result: DatagridContainer<Cliente>) => {
          if(result.data != undefined && result.totalItems != undefined) {
            this.clientes = result.data;
            this.totalItems = result.totalItems;
          } else {
            this.clientes = [];
            this.totalItems = 0;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          this.loading = false;
        }
      );
  }

  onSearch() {

  }

  onClearSearch() {

  }

  inputClienteEdit: InputClienteEdit = undefined;

  onShowEditCliente(id: number) {
    this.inputClienteEdit = { clienteId: id };
  }

  onCloseEditCliente(refresh: boolean) {
    if(refresh)
      this.refresh(this.refreshState);
    this.inputClienteEdit = undefined;
  }

  /* #endregion Events */

}
