import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuracion } from '../shared/models/configuracion.model';
import { environment } from 'src/environments/environment';
import { ResponseMessage } from '../shared/models/response-message.model';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionService {

  constructor(private http: HttpClient) { }

  getConfiguracion() : Observable<Configuracion[]>  {
    return this.http.get<Configuracion[]>(`${environment.apiUrl}admin/configuracion`);
  }

  updateInformacion(configuraciones: Configuracion[]) : Observable<ResponseMessage<any>> {
    return this.http.put<ResponseMessage<any>>(`${environment.apiUrl}admin/configuracion`, { configuraciones });
  }
}
