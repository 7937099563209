import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClrForm, ClrLoadingState } from '@clr/angular';
import { ProductosService } from '../../productos.service';
import { ResponseMessage } from 'src/app/shared/models/response-message.model';
import { Toast, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-productos-new',
  templateUrl: './productos-new.component.html',
  styleUrls: ['./productos-new.component.css'],
})
export class ProductosNewComponent implements OnInit {
  @Output() onCloseEventEmitter = new EventEmitter<OutputProductoNew>();
  @ViewChild(ClrForm) clrForm;
  saveBtn: ClrLoadingState = ClrLoadingState.DEFAULT;
  saveEditBtn: ClrLoadingState = ClrLoadingState.DEFAULT;
  formGroup;
  constructor(private productoService: ProductosService, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      marca: new FormControl('Plagron', Validators.required),
      nombre: new FormControl('', Validators.required),
      resumen: new FormControl('', Validators.required),
    });
  }

  onClose() {
    this.onCloseEventEmitter.emit({ refresh : false, productoId: undefined});
  }

  onSave() {
    if (this.formGroup.invalid) {
      this.clrForm.markAsTouched();
      return;
    }
     else {
      this.saveBtn = ClrLoadingState.LOADING;
      this.productoService.createProducto(this.formGroup.get('marca').value, this.formGroup.get('nombre').value,this.formGroup.get('resumen').value,).subscribe((result: ResponseMessage<any>) => {
        if(result.success) {
          this.toastr.success('Se agrego el producto.')
          this.onCloseEventEmitter.emit({ refresh : true, productoId: undefined});
        } else {
          this.toastr.success(result.message);
        }
      },() => {
        this.saveBtn = ClrLoadingState.DEFAULT;
      });
    }
  }

  onSaveEdit() {
    if (this.formGroup.invalid) {
      this.clrForm.markAsTouched();
      return;
    } else {
      this.saveBtn = ClrLoadingState.LOADING;
      this.productoService.createProducto(this.formGroup.get('marca').value, this.formGroup.get('nombre').value,this.formGroup.get('resumen').value,).subscribe((result: ResponseMessage<any>) => {
        if(result.success) {
          this.toastr.success('Se agrego el producto.')
          this.onCloseEventEmitter.emit({ refresh : true, productoId: result.entity});
        } else {
          this.toastr.success(result.message);
        }
      },() => {
        this.saveBtn = ClrLoadingState.DEFAULT;
      });
    }
  }
}

export class OutputProductoNew {
  refresh: boolean;
  productoId: number | undefined;
}
