import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ClrForm, ClrLoadingState } from '@clr/angular';
import { ProductosService } from '../../productos.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductoConsejo } from 'src/app/shared/models/producto-consejo.model';
import { ResponseMessage } from 'src/app/shared/models/response-message.model';

@Component({
  selector: 'app-productos-edit-consejos-edit',
  templateUrl: './productos-edit-consejos-edit.component.html',
  styleUrls: ['./productos-edit-consejos-edit.component.css']
})
export class ProductosEditConsejosEditComponent implements OnInit {
  @Input() inputConsejoEdit: InputConsejoEdit;
  @Output() onCloseEventEmitter = new EventEmitter<boolean>();
  @ViewChild(ClrForm) clrForm;
  saveBtn: ClrLoadingState = ClrLoadingState.DEFAULT;
  loadingWindow = true;
  formGroup;
  constructor(private productoService: ProductosService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      id: new FormControl(),
      descripcion: new FormControl('', Validators.required),
    });
    this.productoService
    .getConsejo(this.inputConsejoEdit.productoConsejoId)
    .subscribe((result: ProductoConsejo) => {
      this.formGroup.patchValue({
        id: result.id,
        descripcion: result.descripcion
      });
      this.loadingWindow = false;
    });
  }

  onClose() {
    this.onCloseEventEmitter.emit(false);
  }

  onSave() {
    if (this.formGroup.invalid) {
       this.clrForm.markAsTouched();
    } else {
       this.saveBtn = ClrLoadingState.LOADING;
       this.productoService
         .updateConsejo(
           this.inputConsejoEdit.productoConsejoId,
           this.formGroup.get('descripcion').value,
         )
         .subscribe(
           (result: ResponseMessage<any>) => {
             if (result.success) {
               this.toastr.success("Se actualizó el consejo.");
               this.onCloseEventEmitter.emit(true);
             } else {
               this.toastr.error(result.message);
             }
           },
           () => {
             this.saveBtn = ClrLoadingState.DEFAULT;
           }
         );
    }
  }

}

export class InputConsejoEdit {
  productoConsejoId: number;
}
