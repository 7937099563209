import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClrLoadingState } from '@clr/angular';
import { ProductoPreguntaFrecuente } from 'src/app/shared/models/producto-preguntafrecuente.model';
import { ProductosService } from '../../productos.service';

@Component({
  selector: 'app-productos-edit-preguntasfrecuentes',
  templateUrl: './productos-edit-preguntasfrecuentes.component.html',
  styleUrls: ['./productos-edit-preguntasfrecuentes.component.css']
})
export class ProductosEditPreguntasfrecuentesComponent implements OnInit {
  @Input() productoId: number;
  @Output() onCloseEventEmitter = new EventEmitter<boolean>();
  saveBtn: ClrLoadingState = ClrLoadingState.DEFAULT;
  productoPreguntasFrecuentes: ProductoPreguntaFrecuente[] = [];
  loadingWindow = true;
  constructor(private productoService: ProductosService) { }

  ngOnInit(): void {
    this.productoService.getPreguntasFrecuentes(this.productoId).subscribe((result: ProductoPreguntaFrecuente[]) => {
      this.productoPreguntasFrecuentes = result;
    })
  }

  onClose() {
    this.onCloseEventEmitter.emit(false);
  }

  onSave() {

  }
}
