<div class="login-wrapper">
  <form clrForm class="login" [formGroup]="loginForm">
    <section class="title">
      <div class="content">
        <div class="images" style="align-items: center; padding:10px;">
          <img class="login-img" src="./../../../assets/retina-logo.jpeg" style="margin-left: 35px;">
          </div>
      </div>
    </section>
      <div class="login-group">
          <clr-input-container>
              <label class="clr-sr-only">Usuario</label>
              <input type="text" name="usuario" clrInput placeholder="Usuario" formControlName="usuario"/>
          </clr-input-container>
          <clr-password-container>
              <label class="clr-sr-only">Contraseña</label>
              <input type="password" name="contrasenia" clrPassword placeholder="Contraseña" formControlName="contrasenia"/>
          </clr-password-container>
          <div *ngIf="error" class="error active" [innerHtml]="errorMessage"></div>
          <button type="submit" class="btn btn-primary" [clrLoading]="loginBtnState" (click)="onLogin()">Ingresar</button>
      </div>
  </form>
</div>
