<clr-modal [clrModalOpen]="true" [clrModalClosable]="false" class="modal-line-height" [clrModalSize]="'lg'">
  <h3 class="modal-title">Edición masiva de productos</h3>
  <div class="modal-body">
  <app-modal-spinner *ngIf="loading"></app-modal-spinner>
    <div class="clr-row" *ngIf="!loading">
      <div class="clr-col-12">
        <button type="button" class="btn btn-sm btn-primary" (click)="onReestablecer()"><clr-icon shape="refresh"></clr-icon> Reestablecer</button>
        <button type="button" class="btn btn-sm btn-primary right-element" (click)="onOpenIncreasePrecio()"
          [disabled]="countSelected() < 1"><clr-icon shape="dollar"></clr-icon> Incrementar Masivamente</button>
      </div>
      <table class="table table-compact">
        <thead>
          <tr>
            <th class="col-checkbox"><input clrCheckbox type="checkbox" (change)="onSelectAll($event)"></th>
            <th class="left">Productos</th>
            <th class="left">Presentaciones</th>
            <th class="left" style="max-width: 100px; width: 100px; min-width: 100px;">Stock</th>
            <th class="left col-precio" style="max-width: 120px; width: 120px; min-width: 120px;">Precio ($)</th>
          </tr>
        </thead>
        <tbody>
          <ng-template ngFor let-producto [ngForOf]="productos">
            <tr *ngFor="let productoPresentacion of producto.presentaciones; index as i">
              <td class="col-checkbox"><input clrCheckbox type="checkbox" [(ngModel)]="productoPresentacion.seleccionado"></td>
              <td *ngIf="i == 0" class="left td-presentacion" style="vertical-align: middle;" [attr.rowspan]="producto.presentaciones?.length"><span
                  class="p2">{{producto.nombre}}</span></td>
              <td class="left">
                <span class="p2">{{ productoPresentacion.descripcion }}</span>
              </td>
              <td class="right">
                <clr-input-container style="margin-top: 0px;">
                  <input type="text" clrInput class="input-stock" [class.input-modified] = "productoPresentacion.stock != productoPresentacion.sockOriginal"  [(ngModel)]="productoPresentacion.stock" />
                </clr-input-container>
              </td>
              <td class="right col-precio">
                <clr-input-container style="margin-top: 0px;">
                  <input type="text" clrInput class="input-precio" [class.input-modified] = "productoPresentacion.precio != productoPresentacion.precioOriginal" [(ngModel)]="productoPresentacion.precio" />
                </clr-input-container>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="onClose()" [disabled]="saveBtn == 1">Cerrar</button>
    <button type="button" class="btn btn-primary" (click)="onSave()" [clrLoading]="saveBtn">Grabar</button>
  </div>
</clr-modal>

<app-productos-edit-masivos-precio *ngIf="inputIncreasePrecio"
  (onCloseEventEmitter)="onCloseIncreasePrecio($event)"></app-productos-edit-masivos-precio>
