<div class="clr-row">
  <div class="clr-col-6">
    <h2 class="title-page">
      <clr-icon shape="employee" size="28"></clr-icon> Clientes
    </h2>
  </div>
  <div class="clr-col-6">
    <!-- <button class="btn btn-primary button-nuevo">
      <clr-icon shape="plus-circle"></clr-icon> Nuevo
    </button> -->
  </div>
</div>
<hr />
<div class="clr-row">
  <div class="clr-col-lg-5 clr-col-12 search-col">
    <div class="search-container">
      <label for="search_input" class="label-search">
        <clr-icon shape="filter-grid" class="is-solid" size="22"></clr-icon>
        <input
          disabled
          id="search_input"
          class="clr-input clr-input-75"
          type="text"
          [(ngModel)]="searchPattern"
          placeholder=""
        />
        <clr-icon
          (click)="onClearSearch()"
          shape="close"
          class="click-icon"
        ></clr-icon>
        <clr-icon
          (click)="onSearch()"
          shape="search"
          class="click-icon"
        ></clr-icon>
      </label>
    </div>
  </div>
  <div class="clr-col-lg-5 clr-col-12">
    <clr-select-container>
      <label>Estado</label>
      <select clrSelect name="empresasOptions" disabled>
        <option value="">Todas</option>
      </select>
    </clr-select-container>
  </div>
  <div class="clr-col-lg-2 clr-col-12"></div>
</div>
<clr-datagrid
  (clrDgRefresh)="refresh($event)"
  [clrDgLoading]="loading"
  class="datagrid-compact"
>
  <clr-dg-column>#</clr-dg-column>
  <clr-dg-column>Apellido</clr-dg-column>
  <clr-dg-column>Nombre</clr-dg-column>
  <clr-dg-column>Correo eléctronico</clr-dg-column>
  <clr-dg-column>Ult. Visita</clr-dg-column>
  <clr-dg-column>Verificado</clr-dg-column>
  <clr-dg-column>Activo</clr-dg-column>
  <clr-dg-column>Acciones</clr-dg-column>
  <clr-dg-row *ngFor="let cliente of clientes">
    <clr-dg-cell>{{cliente.id}}</clr-dg-cell>
    <clr-dg-cell>{{cliente.nombre}}</clr-dg-cell>
    <clr-dg-cell>{{cliente.apellido}}</clr-dg-cell>
    <clr-dg-cell>{{cliente.correoElectronico}}</clr-dg-cell>
    <clr-dg-cell>{{cliente.ultimoAcceso |  date:'dd/MM/yyyy HH:mm' }}</clr-dg-cell>
    <clr-dg-cell class="text-center">
      <div *ngIf="cliente.verificado; else elseEstaVerificado"><clr-icon shape="check" size="18" class="is-solid is-green"></clr-icon></div>
      <ng-template #elseEstaVerificado><clr-icon shape="close" size="18" class="is-solid is-red"></clr-icon></ng-template>
    </clr-dg-cell>
    <clr-dg-cell class="text-center">
      <div *ngIf="cliente.activo; else elseEstaActivo"><clr-icon shape="circle" size="18" class="is-solid is-green"></clr-icon></div>
      <ng-template #elseEstaActivo><clr-icon shape="circle" size="18" class="is-solid is-red"></clr-icon></ng-template>
    </clr-dg-cell>

    <clr-dg-cell><clr-icon class="datagrid-edit-icon" title="Editar" shape="note" (click)="onShowEditCliente(cliente.id)"></clr-icon></clr-dg-cell>
  </clr-dg-row>
  <clr-dg-footer>  <clr-dg-pagination #pagination [clrDgPageSize]="15"
    [clrDgTotalItems]="totalItems">
        <clr-dg-page-size [clrPageSizeOptions]="[10,15, 25, 50]">Pedidos por página</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
        of {{totalItems}} pedidos
    </clr-dg-pagination></clr-dg-footer>
</clr-datagrid>

<app-cliente-edit *ngIf="inputClienteEdit" [inputClienteEdit]="inputClienteEdit" (onCloseEventEmitter)="onCloseEditCliente($event)"></app-cliente-edit>
