import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductosComponent } from './pages/productos/productos.component';
import { ProductosNewComponent } from './components/productos-new/productos-new.component';
import { ProductosEditComponent } from './components/productos-edit/productos-edit.component';
import { ModalSpinnerComponent } from '../shared/components/modal-spinner/modal-spinner.component';
import { ProductosEditGeneralComponent } from './components/productos-edit-general/productos-edit-general.component';
import { ProductosEditPresentacionesComponent } from './components/productos-edit-presentaciones/productos-edit-presentaciones.component';
import { ProductosEditVentajasComponent } from './components/productos-edit-ventajas/productos-edit-ventajas.component';
import { ProductosEditConsejosComponent } from './components/productos-edit-consejos/productos-edit-consejos.component';
import { ProductosEditPreguntasfrecuentesComponent } from './components/productos-edit-preguntasfrecuentes/productos-edit-preguntasfrecuentes.component';
import { ProductosEditFotosComponent } from './components/productos-edit-fotos/productos-edit-fotos.component';
import { ProductosEditPresentacionesEditComponent } from './components/productos-edit-presentaciones-edit/productos-edit-presentaciones-edit.component';
import { ProductosEditPresentacionesAddComponent } from './components/productos-edit-presentaciones-add/productos-edit-presentaciones-add.component';
import { ProductosEditVentajasAddComponent } from './components/productos-edit-ventajas-add/productos-edit-ventajas-add.component';
import { ProductosEditVentajasEditComponent } from './components/productos-edit-ventajas-edit/productos-edit-ventajas-edit.component';
import { ProductosEditConsejosEditComponent } from './components/productos-edit-consejos-edit/productos-edit-consejos-edit.component';
import { ProductosEditConsejosAddComponent } from './components/productos-edit-consejos-add/productos-edit-consejos-add.component';
import { ProductosEditPreguntasfrecuentesAddComponent } from './components/productos-edit-preguntasfrecuentes-add/productos-edit-preguntasfrecuentes-add.component';
import { ProductosEditPreguntasfrecuentesEditComponent } from './components/productos-edit-preguntasfrecuentes-edit/productos-edit-preguntasfrecuentes-edit.component';
import { ProductosEditMasivosComponent } from './components/productos-edit-masivos/productos-edit-masivos.component';
import { ProductosEditMasivosPrecioComponent } from './components/productos-edit-masivos-precio/productos-edit-masivos-precio.component';

@NgModule({
  declarations: [
    ProductosComponent,
    ProductosNewComponent,
    ProductosEditComponent,
    ModalSpinnerComponent,
    ProductosEditGeneralComponent,
    ProductosEditPresentacionesComponent,
    ProductosEditVentajasComponent,
    ProductosEditConsejosComponent,
    ProductosEditPreguntasfrecuentesComponent,
    ProductosEditFotosComponent,
    ProductosEditPresentacionesEditComponent,
    ProductosEditPresentacionesAddComponent,
    ProductosEditVentajasAddComponent,
    ProductosEditVentajasEditComponent,
    ProductosEditConsejosEditComponent,
    ProductosEditConsejosAddComponent,
    ProductosEditPreguntasfrecuentesAddComponent,
    ProductosEditPreguntasfrecuentesEditComponent,
    ProductosEditMasivosComponent,
    ProductosEditMasivosPrecioComponent
  ],
  imports: [CommonModule, ClarityModule, FormsModule, ReactiveFormsModule],
})
export class ProductosModule {}
