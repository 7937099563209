
<form clrForm clrLayout="horizontal" [formGroup]="formGroup">
  <clr-checkbox-container style="float: right;">
    <clr-checkbox-wrapper>
      <label>Activo</label>
      <input type="checkbox" clrCheckbox name="activo" formControlName="activo" />
    </clr-checkbox-wrapper>
  </clr-checkbox-container>
  <clr-input-container>
    <label>Marca</label>
          <input clrInput type="text" name="marca" formControlName="marca" />
          <clr-control-error>El campo marca es requerido.</clr-control-error>
      </clr-input-container>
      <clr-input-container>
        <label>Nombre</label>
          <input clrInput type="text" name="nombre" formControlName="nombre" />
        <clr-control-error>El campo nombre es requerido.</clr-control-error>
      </clr-input-container>
      <clr-textarea-container>
        <label>Resúmen</label>
        <textarea clrTextarea name="resumen" formControlName="resumen" rows="1" style="width: 100%;" required></textarea>
        <clr-control-error>El resúmen nombre es requerido.</clr-control-error>
      </clr-textarea-container>
      <clr-textarea-container>
        <label>Descrip.</label>
        <textarea clrTextarea name="descripcion" formControlName="descripcion" rows="1" style="width: 100%;"></textarea>
      </clr-textarea-container>
      <clr-textarea-container>
        <label>Descrip.</label>
        <textarea clrTextarea name="dosificacion" formControlName="dosificacion" rows="1" style="width: 100%;"></textarea>
      </clr-textarea-container>
</form>
<div class="clr-row">
  <div class="clr-col-12">
    <button type="button" class="btn btn-primary" [clrLoading]="saveBtn" (click)="onSave()" style="float: right;">Grabar</button>
    <button type="button" class="btn" (click)="onClose()" [disabled]="saveBtn == 1" style="float: right;">Cerrar</button>
  </div>
</div>
