<clr-modal
  [clrModalOpen]="true"
  [clrModalClosable]="false"
  class="modal-line-height"
>
  <h3 class="modal-title">Agregar presentación</h3>
  <div class="modal-body">
    <div>
      <form clrForm clrLayout="horizontal" [formGroup]="formGroup">
        <clr-input-container>
          <label>Código</label>
          <input clrInput type="text" name="marca" formControlName="codigo" />
          <clr-control-error>El campo código es requerido.</clr-control-error>
        </clr-input-container>
        <clr-input-container>
          <label>Descripción</label>
          <input
            clrInput
            type="text"
            name="marca"
            formControlName="descripcion"
          />
          <clr-control-error
            >El campo descripción es requerido.</clr-control-error
          >
        </clr-input-container>
        <clr-input-container>
          <label>Precio</label>
          <input clrInput type="numeric" name="precio" formControlName="precio" />
          <clr-control-error>El campo precio es requerido.</clr-control-error>
        </clr-input-container>
        <clr-input-container>
          <label>Stock</label>
          <input clrInput type="numeric" name="stock" formControlName="stock" />
          <clr-control-error>El campo stock es requerido.</clr-control-error>
        </clr-input-container>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="onClose()" [disabled]="saveBtn == 1">Cerrar</button>
    <button type="button" class="btn btn-primary" (click)="onSave()" [clrLoading]="saveBtn">Grabar</button>
  </div>
</clr-modal>
