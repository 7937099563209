import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ClrLoadingState } from '@clr/angular';
import { ProductosService } from '../../productos.service';
import { ProductoMasivo, ProductoPresentacionMasivo } from 'src/app/shared/models/producto-masivo.model';
import { OutputProductosEditMasivosPrecio } from '../productos-edit-masivos-precio/productos-edit-masivos-precio.component';
import { hasAttributeAndIsNotEmpty } from '@cds/core/internal';
import { ResponseMessage } from 'src/app/shared/models/response-message.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-productos-edit-masivos',
  templateUrl: './productos-edit-masivos.component.html',
  styleUrls: ['./productos-edit-masivos.component.css']
})
export class ProductosEditMasivosComponent implements OnInit {
  @Output() onCloseEventEmitter = new EventEmitter<boolean>();
  saveBtn: ClrLoadingState = ClrLoadingState.DEFAULT;
  productos: ProductoMasivo[] = [];
  loading = true;
  constructor(private productosService: ProductosService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.load();
  }

  // #region Edit Productos Masivos

  load() {
    this.productosService.getMasivos().subscribe((result: ProductoMasivo[]) => {
      this.productos = result;
      this.productos.forEach(p => {
        p.presentaciones.forEach(pp => {
          pp.precioOriginal = pp.precio;
          pp.sockOriginal = pp.stock;
        });
        this.loading = false;
      });
    });
  }

  onReestablecer() {
    this.load();
  }

  onSelectAll(e) {
    if (e.currentTarget.checked) {
      this.productos.forEach(p => { p.presentaciones.forEach(pp => { pp.seleccionado = true; }) });
    }
    else {
      this.productos.forEach(p => { p.presentaciones.forEach(pp => { pp.seleccionado = false; }) });
    }
  }

  countSelected() {
    let count = 0;
    this.productos.forEach(p => { p.presentaciones.forEach(pp => { if (pp.seleccionado == true) { count++ } }) });
    return count;
  }

  onClose() {
    this.onCloseEventEmitter.emit(false);
  }

  onSave() {
    this.saveBtn = ClrLoadingState.LOADING;
    let productoPresentacionMasivo : { id: number, stock: number, precio: number }[] = [];
    this.productos.forEach(p => {
      p.presentaciones.forEach(pp => {
        if (pp.precioOriginal != pp.precio || pp.sockOriginal != pp.stock) {
          productoPresentacionMasivo.push({ id: pp.id, stock: Number(pp.stock), precio: Number(pp.precio) });
        }
      })
    });
    this.productosService.putMasivos(productoPresentacionMasivo).subscribe((result: ResponseMessage<any>) => {
      if(result.success) {
        this.load();
        this.toastr.success("Se actualizó la información");
      } else {
        this.toastr.error(result.message);
      }
      this.saveBtn = ClrLoadingState.DEFAULT;
    });
  }

  // #region Edit Productos Masivos

  // #region Incremento Precios

  inputIncreasePrecio = undefined;

  onOpenIncreasePrecio() {
    this.inputIncreasePrecio = true;
  }

  onCloseIncreasePrecio(outputProductosEditMasivosPrecio: OutputProductosEditMasivosPrecio) {
    if (outputProductosEditMasivosPrecio.aplicar) {
      this.productos.forEach(p => {
        p.presentaciones.forEach(pp => {
          if (pp.seleccionado == true) {
            pp.precio = pp.precio + (Math.round(pp.precio * (outputProductosEditMasivosPrecio.porcentajeAumento / 100)));
          }
        })
      });
    }
    this.inputIncreasePrecio = undefined;
  }

  // #endregion Incremento Precios
}
