import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClrLoadingState } from '@clr/angular';
import { ProductoVentaja } from 'src/app/shared/models/producto-ventaja.model';
import { ProductosService } from '../../productos.service';
import { InputVentajaEdit } from '../productos-edit-ventajas-edit/productos-edit-ventajas-edit.component';

@Component({
  selector: 'app-productos-edit-ventajas',
  templateUrl: './productos-edit-ventajas.component.html',
  styleUrls: ['./productos-edit-ventajas.component.css']
})
export class ProductosEditVentajasComponent implements OnInit {
  @Input() productoId: number;
  @Output() onCloseEventEmitter = new EventEmitter<boolean>();
  saveBtn: ClrLoadingState = ClrLoadingState.DEFAULT;
  productoVentajas: ProductoVentaja[] = [];
  loadingWindow = true;
  constructor(private productoService: ProductosService) { }

  ngOnInit(): void {
    this.getVentajas();
  }

  getVentajas() {
    this.productoService.getVentajas(this.productoId).subscribe((result: ProductoVentaja[]) => {
      this.productoVentajas = result;
    });
  }

  onClose() {
    this.onCloseEventEmitter.emit(false);
  }

    /* #region Ventaja New */
    inputVentajaAdd: boolean | undefined = undefined;

    onShowVentajaAdd() {
      this.inputVentajaAdd = true;
    }

    onCloseVentajaAdd(refresh) {
      if(refresh)
        this.getVentajas();
      this.inputVentajaAdd = undefined;
    }
    /* #endregion Ventaja New */

    /* #region Ventaja Edit */
    inputVentajaEdit: InputVentajaEdit = undefined;

    onShowVentajaEdit(productoVentajaId: number) {
       this.inputVentajaEdit = {
        productoVentajaId: productoVentajaId,
       };
    }

    onCloseVentajaEdit(refresh) {
       if(refresh)
         this.getVentajas();
       this.inputVentajaEdit = undefined;
    }
    /* #endregion Ventaja Edit */
}
